.leaderboard-container {
  padding: 0.5rem .75rem 0;
  @include breakpoint(sm) {
    padding: 0.5rem 1.5rem 0;
  }
  @media screen and (min-width: 1480px) {
    max-width: 1050px;
  }
  .share-link-header {
    color: $white;
    font-size: 1rem;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    @include breakpoint(sm) {
      font-size: 1.4rem;
    }
  }
}
.leaderboard-cta {
  color: $white;
  font-size: 1.1rem;
  margin-bottom: .5rem;
  text-transform: uppercase;
  @include breakpoint(md) {
  font-size: 1.4rem;
  margin-bottom: 2rem;
  }
}
.group.container {
  @media screen and (min-width: 1480px) {
    max-width: 1050px;
  }
}
.groups-header {
  padding: 2rem 0 1rem 0;
  text-transform: uppercase;
  @include breakpoint(md) {
    padding: 2rem 0;
  }
  h1 {
      color: $white;
      font-size: 1.1rem;
      @include breakpoint(md) {
        font-size: 1.4rem;
      }
  }
}
.groups-table-header {
  padding: 1rem 2.5rem;
  color: #BA0020;
  font-weight: $font-weight-bold;
  font-size: 1.4rem;
  border-bottom: 1px solid rgba(#B5B5B5, .3);
}
.leaderboard-header{
  display: flex;
  justify-content: center;
  max-width: 1050px;
  margin: 0 auto;
  padding: 2rem 0;
  @include breakpoint(0, sm) {
    flex-direction: column;
    padding: .75rem;
  }
  h1{
  color: $white;
}
.button-group {
  @include breakpoint(0, sm) {
    display: flex;
    flex-direction: row;
  }
  button {
    margin-right: 1rem;
    @include breakpoint(0, sm) {
      margin-right: .5rem;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
}

&-joined {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0 0;
  @include breakpoint(md) {
    margin: 2rem 0;
  }
  h1 {
  color: $white;
  }
}
}
.leaderboard-nav {
  color: $white;
  max-width: 1050px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 2rem;
  .pill {
    background: $white;
    color: $text;
    display: inline;
    padding: .25rem 1rem;
    border-radius: 1rem;
    font-weight: $font-weight-book;
  }
  .privacy-status {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }
  .nav-header {
    font-size: 1.3rem;
    font-weight: $font-weight-bold;
   align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    @include breakpoint(md) {
      font-size: 1.6rem;
    }
  }
  .back-button {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    a {
      width: 100%;
    }
    .btn {
      @include breakpoint(0, xs) {
        font-size: .7rem;
      }
    }
  }
}
.leaderboard-header-info {
  display: flex;
  padding: 0.5rem 2.5rem;
  align-items: center;
  .username-header {
    color: $ctc-red;
    width: 44%;
    margin-left: 6%;
    font-size: 0.8rem;
    @include breakpoint(md) {
      font-size: 1.3rem;
    }
  }
  .score {
    color: $ctc-red;
    font-size: 0.8rem;
    width: 50%;
    @include breakpoint(md) {
      font-size: 1.3rem;
    }
  }
}
.groups-table {
  width: 100%;
  background: $white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
  padding: 0;
  .user-info {
    display: flex;
    align-items: center;
    max-width: calc(100% - 116px);
    @include breakpoint(md) {
      max-width: calc(100% - 244px);
    }
  }
  .entry {
    display: flex;
    padding: 1rem 1.5rem;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(#B5B5B5, .3);
    @include breakpoint(md) {
      padding: 1rem 2.5rem;
    }
  &:last-of-type {
    border-bottom: 0;
  }
  }
  .username {
    font-size: 1rem;
    font-weight: $font-weight-book;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include breakpoint(md) {
      font-size: 1.3rem;
    }
  }
}
.leaderboard-table {
  width: 100%;
  background: $white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
  padding: 0;
  overflow: hidden;
  .leaderboard-table_user-rank {
    text-align: center;
    width: 100px;
  }
  .user-info {
    display: flex;
    align-items: center;
  }
.entry {
  display: flex;
  padding: .5rem 2.5rem;
  align-items: center;
  justify-content: space-between;
  &:nth-child(n+2) {
    border-top: 1px solid rgba(#B5B5B5, .3);
  }
  &.user {
    background-color: rgba(#478BA5, .1);
  }

  @include breakpoint(0, sm) {
    padding: .5rem;
  }
}
.username {
  font-size: .8rem;
  font-weight: $font-weight-book;
  margin-left: .25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: nowrap;

  @include breakpoint(0, 400) {
    max-width: 57px;
  }
  @include breakpoint(md) {
    font-size: 1.2rem;
    margin-left: 1rem;
  }
  @media only screen and (min-width: 768px) and (max-width: 1200px){
    font-size: 1rem;
    max-width: 112px;
   }
}
.ticket-count {
  display: flex;
  align-items: center;
   font-weight: $font-weight-book;
   margin-right: 1vw;
  font-size: .7rem;
  min-width: 42px;


  @include breakpoint(md) {
    font-size: 1.2rem;
    min-width: 95px;
  }
   @include breakpoint(xl) {
    margin-right: 4vw;
  }
  @media only screen and (min-width: 768px) and (max-width: 1200px){
    font-size: 1rem;
   }
  img {
    margin-right: .1rem;
    max-width: 17px;
    margin-top: -1px;
    @include breakpoint(md) {
      max-width: 30px;
      margin-right: .25rem;
      margin-top: -2px;
    }
  }
}
.medal-container {
  display: flex;
  font-size: .7rem;
  font-weight: $font-weight-book;
  @include breakpoint(md) {
    font-size: 1.2rem;
  }
  @media only screen and (min-width: 768px) and (max-width: 1200px){
    font-size: 1rem;
  }
  img {
    margin-right: .1rem;
    max-width: 13px;
        @include breakpoint(md) {
      margin-top: -2px;
      max-width: 20px;
      margin-right: .25rem;
    }
  }
  .medal-gold,
  .medal-silver {
    margin-right: 1.25rem;
    @include breakpoint(0, sm) {
      margin-right: .5rem;
    }
    @include breakpoint(md) {
      margin-right: .5rem;
    }
  }
  .medal-gold,
  .medal-silver,
  .medal-bronze {
    @include breakpoint(0, 400) {
      min-width: 34px;
    }
    min-width: 47px;
    @include breakpoint(md) {
    min-width: 87px;
    }
  }
}
.award-group {
  display: flex;
}
.place {
  display: inline-flex;
  border-radius: 50%;
  min-width: 6.25rem;
  height: 2.25rem;
  background: transparent;
  color: $ctc-blue;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  position: relative;
  @include breakpoint(0, sm) {
    width: 1.5rem;
    height: 1.5rem;
    font-size: .8rem;
    min-width: 3.25rem;
  }
  @media only screen and (min-width: 768px) and (max-width: 1200px){
    font-size: 1rem;
    min-width: 4.25rem;
   }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.15;
    border-radius: 50%;
  }
}

}



.button-action {
 button {
   padding: .3rem .5rem;
   font-size: .8rem;
   margin-left: .5rem;
   span {
     font-size: .8rem;
     line-height: 1;
   }
   .icon {
     margin-right: .25rem;
     margin-top: -1px;
     &.flip {
       transform: rotate(180deg);
     }
   }
 }
}

.group-button-container {
  display: flex;
     @include breakpoint(md) {
       min-width: 225px;
     }
}