.trivia-archive {
    h1 {
        color: $ctc-red;
        text-align: left;
        text-transform: uppercase;
        font-size: 1.2rem;
        display: inline-block;
        margin-bottom: 0;
        @include breakpoint(sm) {
            font-size: 1.4rem;
        }
        @include breakpoint(md) {
            font-size: 1.6rem;
        }
    }
    .card-title {
        text-transform: uppercase;
    }
    .section-header .col-12{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &.page-heading {
        padding-top: 2.5rem;
        margin-top: -1rem;
        @include breakpoint(sm) {
            padding-top: 0;
            margin-top: 0;
        }
    }
}

.no-pad {
    padding: 0;
}