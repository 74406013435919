.landing_cards {
  display: flex;
  position: relative;
  z-index: 10;

  @include breakpoint(0, xs) {
    flex-direction: column;
  }

  @include breakpoint(sm, md) {
    flex-wrap: wrap;
    justify-content: center;
  }

  @include breakpoint(lg) {
  }

  @include breakpoint(1280, 1799.98) {
    flex-wrap: wrap;
    justify-content: center;
    max-width: calc(100vw - 648px);
    width: calc(320px + 320px + 16px);
  }

  .landing_card {
    @include breakpoint(0, xs) {
      max-width: calc(100vw - 40px);
      width: 320px;
      filter: drop-shadow(8px 8px 16px #aac6e0);
    }

    @include breakpoint(sm, md) {
      width: calc(50% - 24px);
      max-width: 360px;
      filter: drop-shadow(8px 8px 32px #aac6e0);
    }

    @include breakpoint(lg, 1279.98) {
      flex: 1;
      width: 320px;
      max-width: calc(25vw - 32px);
      filter: drop-shadow(8px 8px 54px #aac6e0);
    }

    @include breakpoint(1280, 1799.98) {
      width: calc(50% - 16px);
      max-width: 320px;
      filter: drop-shadow(8px 8px 54px #aac6e0);
    }

    @include breakpoint(1800) {
      flex: 1;
      width: 360px;
      max-width: calc(((100vw - 648px) / 4) - 32px);
      filter: drop-shadow(8px 8px 54px #aac6e0);
    }

    &:nth-of-type(n + 2) {
      @include breakpoint(0, xs) {
        margin-top: 24px;
      }

      @include breakpoint(lg, 1279.98) {
        margin-left: 32px;
      }

      @include breakpoint(1800) {
        margin-left: 32px;
      }
    }

    &:nth-of-type(n + 3) {
      @include breakpoint(sm, md) {
        margin-top: 16px;
      }

      @include breakpoint(1280, 1799.98) {
        margin-top: 16px;
      }
    }

    &:nth-of-type(even) {
      @include breakpoint(sm, md) {
        margin-left: 16px;
      }

      @include breakpoint(1280, 1799.98) {
        margin-left: 16px;
      }
    }

    .landing_card-divider {
      width: 85%;
      height: 1px;
      left: 50%;
      transform: translateX(-50%);
      border-top: 1px dashed $sky;
      position: relative;
      z-index: 10;
    }

    .landing_card-body {
      position: relative;
      width: 100%;

      h1 {
        position: absolute;
        right: 20px;
        z-index: 4;
        color: $white;
        font-weight: $font-weight-bold;
        top: 50%;
        transform: translate(0, -50%);
        text-transform: uppercase;
        margin-bottom: 0;
        line-height: 105%;
        width: calc((211 / 338) * 100%);

        // @include breakpoint(0, xs) {
        //   font-size: 2.5rem;
        // }

        @include breakpoint(0, md) {
          font-size: 2.5rem;
        }

        @include breakpoint(lg, lg) {
          font-size: 2rem;
        }

        @include breakpoint(xl, 1349.98) {
          font-size: 2.5rem;
        }

        @include breakpoint(1350) {
          font-size: 3rem;
        }
      }

      .landing_card-img {
        width: calc((185 / 205) * 100%);
        height: calc((295 / 370) * 100%);
        background-size: cover;
        position: absolute;
        top: calc((36 / 370) * 100%);
        left: 50%;
        z-index: 3;
        overflow: hidden;
        transform: translate(-50%, 0);

        &::before {
          content: '';
          position: absolute;
          top: -1px;
          left: -1px;
          width: calc(100% + 2px);
          height: calc(100% + 2px);
          pointer-events: none;
          z-index: 5;
          background-size: cover;
        }

        img {
          position: absolute;
          transition: all 350ms ease-in;
        }

        .landing_card_play-button {
          transition: all 500ms ease-out;

          .landing_card_play-button_outline {
            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
          }

          .landing_card_play-button_arrow,
          .landing_card_play-button_fill {
            transition: all 500ms ease-out;
          }
        }
      }

      .landing_card-body_bg {
        width: 100%;
        height: auto;
        position: relative;
        z-index: 1;
      }
    }

    .landing_card-footer {
      position: relative;
      width: 100%;
      margin-top: -1px;

      .btn-primary {
        z-index: 2;
        width: calc(100% - 40px) !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include breakpoint(lg, lg) {
          font-size: 16px;
        }

        @include breakpoint(lg) {
          padding: 10px 12px;
        }
      }

      .landing_card-footer_bg {
        width: 100%;
        height: auto;
        position: relative;
        z-index: 1;
      }
    }

    &.landing_card-fairground-flip {
      .landing_card-body {
        h1 {
          span {
            &:nth-of-type(1) {
              display: block;
              font-size: 40%;
              margin-bottom: -3%;
            }

            &:nth-of-type(2) {
              font-size: 123%;
            }
          }
        }

        .landing_card-img {
          img {
            width: calc((110 / 338) * 100%);
            height: auto;
            transform-origin: 50% 50%;

            &:nth-of-type(1) {
              left: calc((8 / 338) * 100%);
              top: calc((24 / 165) * 100%);
              transform: rotate(-6.83deg);
              z-index: 2;
            }

            &:nth-of-type(2) {
              left: calc((24 / 338) * 100%);
              top: calc((32 / 165) * 100%);
              transform: rotate(6.88deg);
              z-index: 1;
            }
          }
        }
      }

      &:hover {
        .landing_card-img {
          img {
            &:nth-of-type(1) {
              transform: rotate(-10.83deg) translate(-0.1rem, -0.3rem);
            }

            &:nth-of-type(2) {
              transform: rotate(12.88deg) translateY(0.2rem);
            }
          }
        }
      }
    }
  }
}

@keyframes stroke {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}
.prize-content {
  h1 {
    font-size: 25px;
    margin: 0 auto;
    right: 0;
    margin-top: -2.75rem;
    left: 0;
    position: absolute;
    @include breakpoint(576) {
      margin-top: -4rem;
    }
    @include breakpoint(768) {
      font-size: 34px;
    }
  }
}
.card-text {
  font-size: .85rem;
  font-weight: $font-weight-bold;
  @include breakpoint(576) {
    font-size: 1rem;
  }
}
.prize-card {
  box-shadow: 0 1px 12px rgb(0, 0, 0, .12), 0 1px 2px rgb(0, 0, 0, .24);
  margin: .25rem .5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: .5rem;
  transition: all .2s ease-in-out;
  overflow: hidden;
  &:hover {
    transform: scale(1.05);
  }
  .card {
    border: none;
    flex-basis: 100%;
    .card-body {
      padding: 1rem 0.25rem;
      &.list-items {
        overflow: hidden;
        .card-text {
          animation: slide .4s forwards ease-in-out;
          transform: translate3d(0, 1000px, 0);
          opacity: 0;
          &:nth-of-type(2) {
            animation-delay: .2s;
          }
          &:nth-of-type(3) {
            animation-delay: .4s;
          }
          &:nth-of-type(4) {
            animation-delay: .6s;
          }
          &:nth-of-type(5) {
            animation-delay: .8s;
          }
          &:nth-of-type(6) {
            animation-delay: 1s;
          }
          &:nth-of-type(7) {
            animation-delay: 1.2s;
          }
          &:nth-of-type(8) {
            animation-delay: 1.4s;
          }
        }
      }
    }
  }
}
.prize-container.no-images {
  .prize-card {
    max-width: 400px;
    .card-body.list-items {
      padding: 1rem 1rem 1.5rem 1rem;
      @include breakpoint(576) {
        padding: 1rem 4rem 2rem 4rem;
      }
    }
    .card-text {
      margin-bottom: .5rem;
    }
  }
}
@keyframes slide{
  0%{
    opacity: 0;
    transform: scale(0.3) translate3d(0,0,0);
  }
  50%{
    opacity: 0.9;
    transform: scale(1.1);
  }
  80%{
    opacity: 1;
    transform: scale(0.89);
  }
  100%{
    opacity: 1;
    transform: scale(1) translate3d(0,0,0);
  }
}
