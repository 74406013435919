.modal {
  .modal-dialog {
    position: relative;

    @include breakpoint(md, 0) {
      max-width: 740px;
    }

    .modal-content {
      @include breakpoint(0, sm) {
        border-radius: 4px;
      }

      @include breakpoint(md, 0) {
        border-radius: 8px;
      }


      .modal-header {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: none;
        padding: 1.5rem 1rem .75rem 1rem;

        & + .modal-body {
          padding-top: 0;
        }

        h3 {
          text-align: center;
        }
        .icon {
          max-width: 50px;
        }
        .prize-data {
          font-size: 1.6rem;
          font-weight: $font-weight-bold;
          margin-left: .5rem;
        }
        .bonus-info {
          display: flex;
          align-items: center;
          margin-top: 1rem;
        }
        .header-img {
          max-width: 100%;
          @include breakpoint(0, sm) {
            max-width: 85%;
          }
        }
      }

      .modal-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-top: none;
        padding-bottom: 3rem;

        p.light.small {
          text-align: center;
        }
      }

      .modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        ul {
          list-style-type: none;
          padding-inline-start: 0;

          li {
            text-align: left;
            margin: 0 0 8px 0;

            svg {
              @include breakpoint(0, md) {
                width: 24px;
                height: 24px;
                margin-right: 8px;
              }

              @include breakpoint(lg) {
                width: 32px;
                height: 32px;
                margin-right: 12px;
              }
            }

            p,
            svg {
              display: inline;
            }
          }
        }

        .btn-lg {
          @include breakpoint(sm, md) {
            padding: 12px 40px;
          }

          @include breakpoint(lg) {
            padding: 12px 40px;
          }
        }

        p {
          &.small {
            margin-top: 4px;
          }
        }
      }

    }
  }
}
.reentry.modal {
  .modal-content {
    padding: 1.25rem;
    @include breakpoint(md) {
      padding: 2rem;
    }
  }
  .icon-presents {
    max-width: 220px;
    margin: 0 auto;
    margin-top: 2rem;
  }
  .reentry-modal-header.modal-header {
    font-size: 1.6rem;
    font-weight: 700;
    padding: 1rem;
    @include breakpoint(md) {
      font-size: 2.5rem;
    }
  }
  .modal-reward-copy {
   margin:  0 0 2rem 0;
   font-size: 1.4rem;
   max-width: 400px;
  }
  .icon {
    max-width: 45px;
    margin-right: .35rem;
  }
  .icon-copy {
    max-width: 25px;
    margin-right: .2rem;
    margin-top: -2px;
  }
  .reward {
    font-size: 1.75rem;
    font-weight: 700;
    margin-top: .5rem;
  }
}

.intro-modal {
  p {
    @include breakpoint(0, sm) {
      max-width: 100%;
    }
  }

  .modal-footer {
    .btn-primary {
    }
  }
}

.score-table {
  border-spacing: 8px;
  border-collapse: initial;
  table-layout: fixed;

  td {
    h4 {
      margin-bottom: 0;

      @include breakpoint(0, 340) {
        font-size: 0.9rem;
      }

      &.link {
        color: $grass;
      }
    }

    svg {
      @include breakpoint(0, sm) {
        width: 32px;
        height: 32px;
      }

      @include breakpoint(md, 0) {
        width: 40px;
        height: 40px;
      }

      .icon-fill-path {
        fill: $grass;
      }
    }
  }

  tr:nth-of-type(n + 2) {
    margin-top: 16px;
  }

  .score-table_total-score {
    position: relative;

    h4 {
      position: relative;

      span:not(.hidden) {
        position: absolute;
        left: 0;
      }

      span.hidden {
        pointer-events: none;
        opacity: 0;
      }
    }
  }

  .time-bonus {
    display: flex;
    align-items: center;
    position: absolute;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    opacity: 0;
    background: $white;
    z-index: 5;
    min-width: 100%;

    @include breakpoint(0, sm) {
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      -webkit-animation: showBonusMobile 1800ms 1 400ms cubic-bezier(0.36, 0.07, 0.19, 0.97);
      animation: showBonusMobile 1800ms 1 400ms cubic-bezier(0.36, 0.07, 0.19, 0.97);
    }

    @include breakpoint(md, 0) {
      top: -4px;
      left: calc(100% + 2px);
      -webkit-animation: showBonusDesktop 1000ms 1 800ms cubic-bezier(0.36, 0.07, 0.19, 0.97);
      animation: showBonusDesktop 1000ms 1 800ms cubic-bezier(0.36, 0.07, 0.19, 0.97);
    }

    @-webkit-keyframes showBonusMobile {
      from {
        opacity: 0;
        transform: translateY(0);
      }
      40% {
        opacity: 1;
        transform: translateY(-50%);
      }

      60% {
        opacity: 1;
        transform: translateY(-50%);
      }
      to {
        opacity: 0;
        transform: translateY(-100%);
      }
    }

    @keyframes showBonusMobile {
      from {
        opacity: 0;
        transform: translateY(0);
      }
      40% {
        opacity: 1;
        transform: translateY(-50%);
      }

      60% {
        opacity: 1;
        transform: translateY(-50%);
      }
      to {
        opacity: 0;
        transform: translateY(-100%);
      }
    }

    @-webkit-keyframes showBonusDesktop {
      from {
        opacity: 0;
        transform: translateY(100%);
      }
      40% {
        opacity: 1;
        transform: translateY(50%);
      }

      60% {
        opacity: 1;
        transform: translateY(50%);
      }
      to {
        opacity: 0;
        transform: translateY(0);
      }
    }

    @keyframes showBonusDesktop {
      from {
        opacity: 0;
        transform: translateY(100%);
      }
      40% {
        opacity: 1;
        transform: translateY(50%);
      }

      60% {
        opacity: 1;
        transform: translateY(50%);
      }
      to {
        opacity: 0;
        transform: translateY(0);
      }
    }

    svg {
      width: 16px;
      height: 16px;
      margin-right: 4px;

      @include breakpoint(0, sm) {
        display: none;
      }

      .icon-fill-path {
        fill: $grass;
      }
    }

    p {
      margin-bottom: 0;
      font-weight: $font-weight-bold;
      color: $grass;

      @include breakpoint(0, xs) {
        font-size: 0.9rem;
      }

      @include breakpoint(sm, sm) {
        font-size: 1.5rem;
      }

      @include breakpoint(md, 0) {
        font-size: 14px;
      }
    }
  }
}

.start-playing-modal {
  .modal-body {
    & > p:not(.small) {
      @include breakpoint(0, xs) {
        max-width: 250px;
      }
    }
  }

  &.fairground-flip_start-playing-modal {
    .modal-dialog {
      @include breakpoint(md, md) {
        max-width: 640px;
      }

      .modal-header {
        padding-bottom: 0 !important;

        h3 {
          @include breakpoint(0, xs) {
            max-width: 220px;
          }
        }
      }
    }
    .btn-primary {
      @include breakpoint(md) {
        min-width: 360px;
      }
    }
  }

  &.spin-to-win_start-playing-modal {
    .modal-body {
      & > p:not(.small) {
        padding-bottom: 0.5rem;

        @include breakpoint(0, xs) {
          max-width: 300px;
        }

        @include breakpoint(md) {
          max-width: 400px;
        }
      }
    }

    .modal-header {
      padding-bottom: 0 !important;
    }
  }
}

.prize-modal {
  .modal-body {
    p {
      @include breakpoint(md) {
        max-width: 500px;
      }
    }
  }
}

.weekly-prize_header {
  position: absolute;
  top: -1em;
  z-index: 30;

  h1 {
    @extend .gotham-uppercase;
    letter-spacing: 0.04em;
    color: $white;
    text-shadow: 0px 3px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    z-index: 2;
    margin-bottom: 0.5em;

    @include breakpoint(0, md) {
      font-size: 1rem;
    }

    @include breakpoint(lg) {
      font-size: 1.2rem;
    }
  }

  img {
    position: absolute;
    z-index: 1;
    width: 130%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.weekly-prize_image {
  width: 80%;
  max-width: 300px;
  height: auto;
  margin-bottom: 0.5em;
}

.weekly-prize_info {
  max-width: 500px;
  margin-bottom: 1rem;

  h1 {
    color: #171717;
    font-size: 3rem;
    letter-spacing: 0;

    @include breakpoint(0, xs) {
      font-size: 2rem;
    }
  }

  p {
    color: #463C24;
    margin-bottom: 0;
    font-size: 1.4rem;
  }
  .icon {
    max-width: 25px;
  }
}

.modal-body {
  padding: 0;
}
@include breakpoint(0, xs) {
.swal2-popup {
 display: flex!important;
 padding-top: 4rem!important;
 padding-bottom: 4rem!important;
 min-width: calc(100% - 15px)!important;
}
.swal2-image {
  position: absolute!important;
    top: -15px!important;
    left: 5px;
}
.swal2-title {
  position: absolute!important;
  top: 16px!important;
  right: 54px!important;
}
.swal2-html-container {
  position: absolute!important;
  max-width: 60%!important;
  text-align: center!important;
  right: 0!important;
  top: 45px!important;
 }
}