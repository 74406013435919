.btn-back {
  //   @extend .ribbon-button;

  background-color: $grass;
  border-color: $grass;
  color: $white;
  transition: all 200ms ease-in-out;
  font-weight: $font-weight-book;
  line-height: 120%;
  letter-spacing: 0.04em;
  border-radius: 300px;

  @include breakpoint(0, lg) {
    padding: 6px 32px;
    font-size: 0.9rem;
  }

  @include breakpoint(xl) {
    padding: 8px 32px;
    font-size: 1.1rem;
  }

  &.btn-icon.btn-icon-before {
    @include breakpoint(md, lg) {
      padding-right: 12px;
      padding-left: 28px;
    }
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    color: $white;
    background-color: $grass;
    border-color: $grass;
  }

  &.disabled,
  &:disabled {
    color: $white;
    background-color: $grass;
    border-color: $grass;
  }

  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus {
    @include focus-outline($grass);
  }

  &.focus,
  &:focus {
    color: $white;
    background-color: $grass;
    border-color: $grass;
    @include focus-outline($grass);
  }

  &:not(.disabled):not(:disabled) {
    &:hover {
      color: $white;
      background-color: darken($grass, 5%);
      border-color: $grass;
    }
  }
}

.btn-primary {
  background: theme('primary');
  border-color: theme('primary');
  color: $white;
  transition: all 200ms ease-in-out;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  line-height: 120%;
  height: 65px;
  border-radius: 300px;
  box-shadow: 0 0 15px rgba(219,0,50,.5);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  @include breakpoint(0, sm) {
    font-size: 1.1rem;
    padding: 12px 24px;
  }

  @include breakpoint(md) {
    padding: 10px 32px;
    font-size: 1.4rem;
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    color: $white;
    background-color: theme('primary');
    border-color: theme('primary');
  }

  &.disabled,
  &:disabled {
    color: $white;
    background-color: theme('primary');
    border-color: theme('primary');
  }

  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus {
    @include focus-outline($navy);
  }

  &.focus,
  &:focus {
    color: $white;
    background-color: theme('primary');
    border-color: theme('primary');
    @include focus-outline($navy);
  }

  &:not(.disabled):not(:disabled) {
    &:hover {
      color: $white;
      background-color: theme('primary');
      border-color: theme('primary');
      box-shadow: 0 0 10px rgba(219,0,50,.5);
    }
  }
}
.btn-white {
  background: $white;
  border-color: $white;
  color: #000;
  transition: all 200ms ease-in-out;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  line-height: 120%;
  border-radius: 300px;

  @include breakpoint(0, sm) {
    font-size: 1rem;
    padding: 6px 15px;
  }

  @include breakpoint(md) {
    padding: 10px 30px;
    font-size: 1.4rem;
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    color: #000;
    background-color:  $white;
    border-color:  $white;
  }

  &.disabled,
  &:disabled {
    color: #000;
    background-color: $white;
    border-color:  $white;
    .false {
      display: none;
    }
    .true {
    height: 23px;
    margin-right: 6px;
    margin-top: -3px;
    }
  }

  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus {
    @include focus-outline($white);
  }

  &.focus,
  &:focus {
    color: #000;
    background-color:  $white;
    border-color:  $white;
    @include focus-outline($navy);
  }

  &:not(.disabled):not(:disabled) {
    &:hover {
      color: #000;
      background-color:  $white;
      border-color:  $white;

    }
  }
}

.btn-outline-primary {
  background-color: #fff;
  border: 2px solid theme('primary');
  color: theme('primary');
  transition: all 200ms ease-in-out;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  line-height: 120%;
  border-radius: 300px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  @include breakpoint(0, sm) {
    font-size: 1rem;
    padding: 12px 24px;
  }

  @include breakpoint(md) {
    padding: 10px 32px;
    font-size: 1.3rem;
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    background-color: $white;
    color: theme('primary');
    border-color: $white;
  }

  &.disabled,
  &:disabled {
    color: theme('primary');
    border-color: theme('primary');
  }

  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus {
    @include focus-outline(theme('primary'));
  }

  &.focus,
  &:focus {
    background-color: $white;
    color: theme('primary');
    border-color: theme('primary');
    @include focus-outline(theme('primary'));
  }

  &:not(.disabled):not(:disabled) {
    &:hover {
      color: theme('primary');
      background-color: $white;
      border-color: theme('primary');
      box-shadow: 0 0 10px rgba(theme('primary'),.5);
    }
  }
  &.white {
    color: $white;
    border-color: $white;
    &:not(.disabled):not(:disabled):hover {
      border-color: $white;
      background-color: $white;
      color: theme('primary');
    }
  }
}

.btn-secondary {
  background: linear-gradient(180deg, rgba(52,122,167,1) 0%, rgba(43,99,135,1) 100%);
  border-color: theme('secondary');
  color: $white;
  transition: all 200ms ease-in-out;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  line-height: 120%;
  border-radius: 300px;
  box-shadow: 0 0 15px rgba(52,122,167,.5);

  @include breakpoint(0, sm) {
    font-size: 1.2rem;
    padding: 12px 24px;
  }

  @include breakpoint(md) {
    padding: 10px 32px;
    font-size: 1.6rem;
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    color: $white;
    background-color: theme('secondary');
    border-color:theme('secondary');
  }

  &.disabled,
  &:disabled {
    color: $white;
    background-color: theme('secondary');
    border-color: theme('secondary');
  }

  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus {
    @include focus-outline(theme('secondary'));
  }

  &.focus,
  &:focus {
    color: $white;
    background-color: $ctc-blue;
    border-color: $ctc-blue;
    @include focus-outline(theme('secondary'));
  }

  &:not(.disabled):not(:disabled) {
    &:hover {
      color: $white;
      background-color:theme('secondary');
      border-color: theme('secondary');
      box-shadow: 0 0 10px rgba(52,122,167,.5);
    }
  }
}

.btn-social {
  color: $white;
  transition: all 200ms ease-in-out;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  line-height: 120%;
  border-radius: 50%;
  transition: all 300ms ease-in;
  padding: 0;
  width: 44px;
  height: 44px;


  @include breakpoint(sm) {
    padding: 0;
    width: 64px;
    height: 64px;
  }

  &::before {
    display: none;
  }

  @media (hover: hover) {
    filter: drop-shadow(2px 2px 20px rgba(58, 83, 106, 0.4));
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    color: $white;
  }

  &.disabled,
  &:disabled {
    color: $white;
  }
  &.btn-social-facebook {
    background: url("data:image/svg+xml,%3Csvg width='69' height='69' viewBox='0 0 69 69' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M37.3094 50.3333V35.1649H41.4511L42 29.9378H37.3094L37.3164 27.3215C37.3164 25.9582 37.4446 25.2277 39.3814 25.2277H41.9707V20H37.8284C32.8527 20 31.1015 22.5357 31.1015 26.8V29.9384H28V35.1655H31.1015V50.3333H37.3094Z' fill='white'/%3E%3C/svg%3E%0A"), #4267B2;
    background-position: center center, center center;
    background-size: cover, cover;
    background-repeat: no-repeat, no-repeat;
    z-index: 6;

    &:not(.disabled):not(:disabled) {
      &:hover {
        color: $white;

        @media (hover: hover) {
          transform: rotate(0deg) translate(0, -0.1rem);
        }
      }
    }

    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):active:focus,
    &.focus,
    &:focus {
      @include focus-outline(#1b95e0);
    }
  }
  &.btn-social-twitter {
    background: url("data:image/svg+xml,%3Csvg width='69' height='69' viewBox='0 0 69 69' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M35.4711 29.5107L35.5315 30.5693L34.5258 30.4397C30.8652 29.9428 27.6672 28.2577 24.9519 25.4275L23.6245 24.0232L23.2825 25.0602C22.5585 27.3719 23.0211 29.8132 24.5296 31.4551C25.3341 32.3625 25.1531 32.4921 23.7653 31.952C23.2825 31.7792 22.8602 31.6496 22.8199 31.7144C22.6792 31.8656 23.1619 33.8316 23.544 34.6094C24.067 35.6896 25.133 36.7482 26.2995 37.3747L27.2851 37.8716L26.1185 37.8932C24.9922 37.8932 24.9519 37.9148 25.0726 38.3685C25.4749 39.7728 27.0638 41.2635 28.8338 41.9116L30.0808 42.3653L28.9947 43.0567C27.3856 44.0505 25.495 44.6122 23.6044 44.6554C22.6993 44.677 21.9551 44.7634 21.9551 44.8282C21.9551 45.0443 24.4089 46.2541 25.8369 46.7294C30.121 48.1337 35.2097 47.5288 39.0312 45.1307C41.7464 43.4239 44.4617 40.0321 45.7288 36.7482C46.4127 34.9982 47.0965 31.8008 47.0965 30.2669C47.0965 29.2731 47.1569 29.1435 48.2832 27.9552C48.9469 27.2639 49.5704 26.5077 49.6911 26.2917C49.8923 25.8812 49.8721 25.8812 48.8464 26.2485C47.1368 26.8966 46.8954 26.8102 47.7401 25.838C48.3637 25.1467 49.1078 23.8936 49.1078 23.5263C49.1078 23.4615 48.8061 23.5695 48.4642 23.764C48.1022 23.98 47.2977 24.3041 46.6943 24.4985L45.6082 24.8658L44.6226 24.1529C44.0796 23.764 43.3153 23.3319 42.913 23.2023C41.8872 22.8998 40.3184 22.943 39.3932 23.2887C36.879 24.2609 35.2901 26.767 35.4711 29.5107Z' fill='white'/%3E%3C/svg%3E%0A"),
      #1b95e0;
    background-position: center center, center center;
    background-size: cover, cover;
    background-repeat: no-repeat, no-repeat;
    z-index: 6;

    &:not(.disabled):not(:disabled) {
      &:hover {
        color: $white;

        @media (hover: hover) {
          transform: rotate(0deg) translate(0, -0.1rem);
        }
      }
    }

    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):active:focus,
    &.focus,
    &:focus {
      @include focus-outline(#1b95e0);
    }
  }

  &.btn-social-pinterest {
    background: url("data:image/svg+xml,%3Csvg width='70' height='69' viewBox='0 0 70 69' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M29.7855 41.9083C29.463 43.3288 29.5882 45.3216 29.7286 46.6198L29.9328 48L30.827 47.0156C31.4796 45.9251 32.4585 44.1379 32.8209 42.7117C33.0163 41.9433 33.8207 38.8094 33.8207 38.8094C34.3443 39.8301 35.8734 40.6956 37.5011 40.6956C42.3407 40.6956 45.8296 36.1413 45.8296 30.483C45.8296 25.0594 41.5022 21 35.9341 21C29.0077 21 25.3291 25.758 25.3291 30.937C25.3291 33.3432 26.5812 36.3431 28.5846 37.2978C28.89 37.4414 29.0532 37.3774 29.1234 37.0766C29.1765 36.8496 29.4478 35.7338 29.5692 35.2157C29.6071 35.0489 29.5882 34.9072 29.4573 34.7442C28.7933 33.9215 28.264 32.4079 28.264 30.9972C28.264 27.3763 30.9446 23.8719 35.511 23.8719C39.4552 23.8719 42.2155 26.6195 42.2155 30.5509C42.2155 34.9926 40.0224 38.0701 37.1691 38.0701C35.5945 38.0701 34.4145 36.739 34.792 35.1032C35.2454 33.1511 36.1219 31.0457 36.1219 29.6369C36.1219 28.3756 35.4598 27.3239 34.092 27.3239C32.4794 27.3239 31.1875 29.0276 31.1875 31.3115C31.1875 32.7649 31.6674 33.7488 31.6674 33.7488C31.6674 33.7488 30.0757 40.6276 29.7855 41.9083Z' fill='white'/%3E%3C/svg%3E%0A"),
      #bd081c;
    background-position: center center, center center;
    background-size: cover, cover;
    background-repeat: no-repeat, no-repeat;
    transform: rotate(8deg) translate(0, 0);
    margin-left: -8px;
    z-index: 5;

    &:not(.disabled):not(:disabled) {
      &:hover {
        color: $white;

        @media (hover: hover) {
          z-index: 7;
          transform: rotate(8deg) translate(0, -0.1rem);
        }
      }
    }

    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):active:focus,
    &.focus,
    &:focus {
      @include focus-outline(#bd081c);
    }
  }

}

.btn-lg {
  @include breakpoint(0, 339.98) {
    font-size: 1rem;
  }

  @include breakpoint(340, sm) {
    font-size: 1.2rem;
    padding: 12px 24px;
  }

  @include breakpoint(md) {
    padding: 16px 32px;
    font-size: 1.5rem;
  }
}

.btn-icon {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    @include breakpoint(0, sm) {
      width: 12px;
      height: 12px;
    }

    @include breakpoint(md) {
      width: 2ch;
      height: 2ch;
    }
  }

  &.btn-safari {
    &::after {
      @include breakpoint(md) {
        width: 1.5ch;
        height: 1.5ch;
      }
    }
  }

  &.btn-icon-before {
    &::after {
      left: 8px;
    }

    @include breakpoint(0, sm) {
      padding-right: 12px;
      padding-left: 24px;
    }

    @include breakpoint(md) {
      padding-right: 20px;
      padding-left: 32px;
    }
  }

  &.btn-icon-after {
    &::after {
      right: 8px;
    }

    @include breakpoint(md) {
      padding-left: 20px;
      padding-right: 32px;
    }
  }

  &.btn-icon-arrow {
    &.btn-icon-before {
      &::after {
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.4282 13.9068L10.9254 13.4345C11.0249 13.3102 11.0249 13.1113 10.9254 13.0119L6.42599 8.51243L10.9254 3.98814C11.0249 3.8887 11.0249 3.68983 10.9254 3.56554L10.4282 3.09322C10.304 2.96893 10.1299 2.96893 10.0056 3.09322L4.78531 8.2887C4.68588 8.41299 4.68588 8.58701 4.78531 8.7113L10.0056 13.9068C10.1299 14.0311 10.304 14.0311 10.4282 13.9068Z' fill='white'/%3E%3C/svg%3E%0A");
      }
    }
  }
}

.btn {
  position: relative;

  & > span {
    transition: opacity 200ms ease-in;
  }

  &:not(.btn-social):not(.btn-icon):not(.btn-game):not(.btn-slider) {
    @include breakpoint(0, 576) {
      width: 100%;
    }
  }

  &.btn-loading {
    &::after {
      opacity: 0.1;
    }
  }

  &:not(.btn-game) {
    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: scaleX(0);
      transform-origin: 0 50%;
      transition: transform 300ms ease-out;
    }

    @media (hover: hover) {
      &:hover {
        &::before {
          transform: scaleX(1);
        }
      }
    }
  }

  .btn-loader {
    $loader-size: 24px;

    position: absolute;
    top: calc(50% - (#{$loader-size} / 2));
    left: calc(50% - (#{$loader-size} / 2));
    border-top: 2px solid rgba(255, 255, 255, 0.2);
    border-right: 2px solid rgba(255, 255, 255, 0.2);
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    border-left: 2px solid $white;
    @include prefix(transform, translateZ(0));
    @include prefix(animation, load8 1.1s infinite linear);
    border-radius: 50%;
    width: $loader-size;
    height: $loader-size;

    &::after {
      border-radius: 50%;
      width: $loader-size;
      height: $loader-size;
    }

    @-webkit-keyframes load8 {
      0% {
        @include prefix(transform, rotate(0deg));
      }
      100% {
        @include prefix(transform, rotate(360deg));
      }
    }
    @keyframes load8 {
      0% {
        @include prefix(transform, rotate(0deg));
      }
      100% {
        @include prefix(transform, rotate(360deg));
      }
    }
  }
}

.btn-share {
  background-color: $white;
  border-color: $white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    background-color: $white;
    border-color: $white;
  }

  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus {
    @include focus-outline($white, 0.2);
  }

  &.focus,
  &:focus {
    background-color: $white;
    border-color: $white;
    @include focus-outline($white, 0.2);
  }

  &:hover {
    background-color: $grass;
    border-color: $grass;
  }

  svg {
    height: 32px;
    width: auto;

    path {
      fill: $white;
    }
  }
}

.btn-arrow {
  &::after {
    position: relative;
    content: '';
    display: inline-block;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='22' viewBox='0 0 16 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 19.7863L12.7027 11.0025L2 2.21875' stroke='white' stroke-width='3' stroke-miterlimit='10' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 1ch;
    height: 1.25ch;
    transform: translate(0, -10%);
    transition: all 200ms ease-out;
    margin-left: 8px;
  }

  &:hover {
    @media (hover: hover) {
      &::after {
        transform: translate(5px, -10%);
        -webkit-transform: translate(5px, -10%);
      }
    }
  }
  &.btn-icon-before {
    position: relative;
    margin-top: 0;
    text-transform: uppercase;
    color: $hallmark-pink;
    text-decoration: none;
    font-weight: $font-weight-bold;

    &::after {
    display: none;
    }
    &::before {
      content: '';
      display: inline-block;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='21' viewBox='0 0 16 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.7031 19.4164L3.00043 10.5832L13.7031 1.75' stroke='%23DE204E' stroke-width='3' stroke-miterlimit='10' stroke-linecap='round'/%3E%3C/svg%3E%0A");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      width: .75ch;
      height: 1.25ch;
      position: relative;
      transform: scaleX(1) translatex(-10px);
      z-index: 1;

    }
    &:hover:before {
      transform: scaleX(1) translatex(-15px);
    }
  }
  &.btn-outline-primary{
    &:after {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='22' viewBox='0 0 16 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 19.7863L12.7027 11.0025L2 2.21875' stroke='%23DE2063' stroke-width='3' stroke-miterlimit='10' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  }
  // &:hover:after {
  //   background-image: url("data:image/svg+xml,%3Csvg width='16' height='22' viewBox='0 0 16 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 19.7863L12.7027 11.0025L2 2.21875' stroke='white' stroke-width='3' stroke-miterlimit='10' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  // }
}
}

.btn-retry {
  &::after {
    position: relative;
    content: '';
    display: inline-block;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='16' viewBox='0 0 18 16' fill-rule='evenodd' clip-rule='evenodd' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.06931 14.0077C7.6024 14.4357 7.57086 15.1612 7.99886 15.6281C8.42686 16.095 9.15232 16.1265 9.61923 15.6985L17.0904 8.84994C17.1087 8.83341 17.1264 8.81627 17.1436 8.79855C17.3213 8.61572 17.4265 8.38673 17.4575 8.14928C17.4641 8.09939 17.4673 8.04936 17.4673 7.9995C17.4672 7.72272 17.3676 7.44514 17.1658 7.22503C17.1368 7.19339 17.1065 7.16374 17.0749 7.13611L9.4986 0.295659C9.02848 -0.128806 8.30327 -0.09179 7.8788 0.378335C7.45434 0.84846 7.49135 1.57367 7.96148 1.99813L13.3388 6.85317L1.67959 6.85317C1.04619 6.85317 0.532725 7.36664 0.532725 8.00003C0.532725 8.63343 1.04619 9.14689 1.67959 9.14689L13.372 9.1469L8.06931 14.0077Z' fill='%23FCD574'/%3E%3C/svg%3E%0A");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 1.25ch;
    height: 1.25ch;
    transition: all 200ms ease-out;

    @include breakpoint(0, 420) {
      margin-left: 8px;
    }

    @include breakpoint(md) {
      margin-left: 8px;
    }
  }

  &:hover {
    @media (hover: hover) {
      &::after {
        transform: translatex(5px);
        -webkit-transform: translatex(5px);
      }
    }
  }
}

.btn-slider {
  &::before {
    display: none;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  &:focus {
    box-shadow: none;
    background: rgba(255, 255, 255, 0.1);
  }
}

.btn-arrow.btn-icon-after.btn-safari {
  &::after,
  &::before {
    opacity: 0;
    display: none;
  }
}

.btn-twitter {
  background:  #1b95e0;
  border:  #1b95e0;
  box-shadow: 0 0 15px rgba(#1b95e0,.5);
  font-size: 1rem;
  padding: 15px 30px;

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    color: $white;
    background-color: #1b95e0;
    border-color: #1b95e0;
  }

  &.disabled,
  &:disabled {
    color: $white;
    background-color: #1b95e0;;
    border-color: #1b95e0;;
  }

  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus {
    @include focus-outline(#1b95e0);
  }

  &.focus,
  &:focus {
    color: $white;
    background-color: #1b95e0;
    border-color:#1b95e0;
    @include focus-outline(#1b95e0);
  }

  &:not(.disabled):not(:disabled) {
    &:hover {
      color: $white;
      background-color: #1b95e0;
      border-color: #1b95e0;
      box-shadow: 0 0 10px rgba(#1b95e0,.5);
    }
  }
}
.btn-sm {
  padding: .5rem 1.25rem;
  font-size: 1.1rem;
  @include breakpoint(0, sm) {
  padding: .5rem .25rem;
  font-size: 1rem;
    }
}

.share-buttons {
  .btn-social-twitter {
    margin-right: .5rem;
    @include breakpoint(sm) {
      margin-right: 1rem;
    }
  }
}
.btn-form-append {
  background: linear-gradient(180deg, rgba(219,0,50,1) 0%, rgba(186,0,32,1) 100%);
  border-radius: 0;
  border: 1px solid #B3B3B3;
  color: $white;
  padding: .75rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
  @include breakpoint(sm) {
    width: 42%;
    border-top-right-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }
  @include breakpoint(md) {

    width: 30%;
  }
  @include breakpoint(lg) {
    width: 20%;
  }
  &:hover,
  &:active {
    color: $white;
    background: linear-gradient(0deg, rgba(219,0,50,1) 0%, rgba(186,0,32,1) 100%);
  }
}

.btn-small {
  font-size: 1rem;
  padding: 0.25rem .5rem;
  .btn-icon {
    height: .8rem;
    margin-right: .2rem;
    margin-top: -3px;
  }

}

.btn.btn-primary.pagination {
  background-color: transparent;
  box-shadow: none;
  background: transparent;
  border-radius: .25rem;
  border-color: $white;
  line-height: 1;
  height: 100%;
  p {
    color: $white;
    margin-bottom: 0;
    line-height: 100%;
  }
  &:not(.disabled):not(:disabled):hover {
    background-color: $white;
    p {
         color: $ctc-red;
    }
  }
  &.active {
    background-color: $white;
    border-color: $ctc-red;
    p {
      color: $ctc-red;
 }
  }
}

.btn-icon-before.btn-back {
  border-color: $ctc-red;
  color: $ctc-red;
  width: auto;
  font-size: .7rem;
  padding: 0.3rem .75rem;
  white-space: nowrap;
  &:hover,
  &:active,
  &:not(.disabled):not(:disabled):hover {
    color: $white;
    background: linear-gradient(0deg, rgba(219,0,50,1) 0%, rgba(186,0,32,1) 100%);
    border-color: $ctc-red;
    box-shadow: 0 0 15px rgba(219,0,50,.5);
  }
  @include breakpoint(md) {
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
  }
}

.btn-landing {
  width: 100%;
  max-width: 440px;
}


.btn-load-more {
  min-width: 160px;
}

.nudge-up {
  transform: translateY(-10%);
}

.btn-trivia {
  margin: 1rem 0;
   font-size: .8rem!important;
   padding: .2rem .5rem;
   margin-top: .5rem;
   max-height: 30px;
   min-width: 70px;
   margin-top: 0;
   white-space: nowrap;
   &:after {
     margin-left: 5px;
   }
  @include breakpoint(1200) {
    font-size: .8rem;
    padding: 0rem .25rem!important;
    min-width: 80px;
    max-height: 24px;
    margin-top: 4px;
  }
  @include breakpoint(1480) {
    max-height: 30px;
    margin-top: 0;
    padding: .2rem .5rem!important;
  }

}
.btn-play-more {
  margin-top: .35rem;
  margin-bottom: 1.5rem;
  @include breakpoint(1200) {
    margin-bottom: 0;
  }
  @include breakpoint(1480) {
    margin-top: .5rem;
  }
}
.btn-preview {
  margin-top: 1rem;
  @include breakpoint(768) {
    margin-top: -2.25rem;
  }
}
.btn-like {
  background: $hallmark-pink;
  border-color: $hallmark-pink;
  box-shadow: 0 0 15px rgba($hallmark-pink, .5);
  display: flex;
  position: absolute;
  z-index: 4;
  right: 1.25rem;
  top: 1.5rem;
  padding: 0.45rem 1rem;
  margin-top: 0!important;
}

.btn-outline-primary.btn-icon-before{
  // &:hover:before {
  //   background-image: url("data:image/svg+xml,%3Csvg width='16' height='21' viewBox='0 0 16 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.7031 19.4164L3.00043 10.5832L13.7031 1.75' stroke='white' stroke-width='3' stroke-miterlimit='10' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  // &:visited,
  // &:active {
  //   background-image: url("data:image/svg+xml,%3Csvg width='16' height='21' viewBox='0 0 16 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.7031 19.4164L3.00043 10.5832L13.7031 1.75' stroke='%23DE204E' stroke-width='3' stroke-miterlimit='10' stroke-linecap='round'/%3E%3C/svg%3E%0A");

  // }
  // }
  &:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='21' viewBox='0 0 16 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.7031 19.4164L3.00043 10.5832L13.7031 1.75' stroke='%23DE204E' stroke-width='3' stroke-miterlimit='10' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: .75ch;
  height: 1.25ch;
  position: relative;
  transform: scaleX(1) translatex(-10px);
  z-index: 1;
}
}
.btn-outline-primary.btn-icon-before.btn-download{
  &:before {
  background-image: url("data:image/svg+xml,%3Csvg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M33.25 23.75V30.0833C33.25 30.9232 32.9164 31.7286 32.3225 32.3225C31.7286 32.9164 30.9232 33.25 30.0833 33.25H7.91667C7.07681 33.25 6.27136 32.9164 5.6775 32.3225C5.08363 31.7286 4.75 30.9232 4.75 30.0833V23.75' stroke='%23DE204E' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.082 15.832L18.9987 23.7487L26.9154 15.832' stroke='%23DE204E' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M19 23.75V4.75' stroke='%23DE204E' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  width: 2ch;
  height: 2ch;
}
}
.btn-link {
  color: $hallmark-pink;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  letter-spacing: .01rem;
  text-decoration: none;
  margin-top: 1rem;
}

.btn-modal {
  box-shadow: none;
  color: $hallmark-pink;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  font-size: .8rem;
  @include breakpoint(768) {
    font-size: 1rem;
  }

  &:active,
  &:focus,
  &:visited,
  &:hover {
    color: $hallmark-pink;
    box-shadow: none;
  }
}

.btn-download:hover:before {
  transform: translateX(-10px)!important;
}