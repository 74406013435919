@import 'typography.scss';

$white: #ffffff;
$text: #463C24;
$hallmark-red: #e21219;
$hallmark-pink: #DE2063;
$ctc-green: #144733;
$ctc-red: #BA0020;
$ctc-blue: #347AA7;
$red: #e7131a;
$gray: #B3B3B3;
$navy: #2b357e;
$grass: #8fb208;
$sky: #7491ac;
$sky-ada: #527489;

$gotham: 'Gotham SSm A', 'Gotham SSm B', Tahoma, Helvetica, sans-serif;
$adoquin: 'Adoquin W00 Bold', 'Times New Roman', Times, serif;
$albra: 'AlbraGrotesk', Tahoma, Helvetica, sans-serif;
$font-weight-light: 300;
$font-weight-book: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;

$shadow-container: 0px 22px 24px 0px rgba(176, 181, 244, 0.15);

$text-light: #868686;
$input-outline: #b3b3b3;
