@mixin onHover() {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}

@mixin onTouch() {
  @media (hover: none) {
    &:hover {
      @content;
    }
  }
}

@mixin prefix($property, $value, $vendors: webkit moz ms o) {
  @if $vendors {
    @each $vendor in $vendors {
      #{"-" + $vendor + "-" + $property}: #{$value};
    }
  }
  #{$property}: #{$value};
}

@mixin focus-outline($color, $opacity: 0.4) {
  box-shadow: 0 0 0 0.2rem $color; /*  Fallback */
  box-shadow: 0 0 0 0.2rem rgba($color, $opacity);
}

@mixin fullScreenHeight {
  min-height: 100vh; /*  Fallback */

  @media screen and (max-width: 991.98px) {
    min-height: -webkit-fill-available;
  }

  @media screen and (max-height: 599.98px) {
    min-height: -webkit-fill-available;
  }
}

// @mixin preloadBackgrounds($img_urls...) {
//   position: relative;

//   &::after {
//     content: '';
//     display: block;
//     opacity: 0;
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 0;
//     height: 0;
//     background-image: #{join($img_urls, (), comma)};
//     overflow: hidden;
//   }
// }

@mixin disclaimerBackground($breakpoint, $bgColor) {
  @include breakpoint(0, $breakpoint) {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 90px;
      background-color: $bgColor;
    }
  }
}
