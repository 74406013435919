.nav-bar {
   z-index: 2;
   .username {
    color: #000;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    letter-spacing: .025rem;
    margin-bottom: 0;
    margin-right: .5rem;
    @include breakpoint(lg) {
      margin-bottom: 0;
      margin-right: 1rem;
      letter-spacing: .05rem;
    }
    .icon {
      max-width: 25px;
      display: none;
      @include breakpoint(lg) {
        display: inline;
      }
    }
  }
  .user-data {
    font-size: .75rem;
    @include breakpoint(sm) {
      font-size: 1.2rem;
    }
  }
  .nav-bar_logout {
    button {
      font-weight: $font-weight-medium;
      color: $hallmark-pink;
      font-size: 1.2rem;
      cursor: pointer;
      background: none;
      border: none;
      position: relative;
      padding: 0;
      display: inline-block;
      transform: translateY(-2px);
      &:after {
        content: '';
        bottom: 0;
        background-color: $hallmark-pink;
        height: 2px;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }
  }
}
.nav-bar-sp0 {
  display: none;
  position: relative;
  width: 100%;
  z-index: 20;
  background-color: #FFFFFF;
  box-shadow: 0 0 30px rgba(#000, .1);
  background-size: cover;

  @include breakpoint(lg) {
    border-radius: 0;
  }
  .nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @include breakpoint(0, md) {
      flex-direction: row;
      padding: 7px 12px;
    }



    @include breakpoint(lg) {
      min-height: 55px;
    }

    @include breakpoint(lg, lg) {
      padding: 8px 55px 8px 29px;
    }

    @include breakpoint(xl) {
      padding: 8px 55px 8px 75px;
    }

    .nav-bar_top,
    .nav-bar_bottom {
      width: 100%;
      @include breakpoint(0, md) {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px 12px;
      }
    }

    .nav-bar_bottom {
      &.nav-bar_bottom-logout {
        justify-content: flex-end;
      }
    }

    .nav-bar_top {
      &.nav-bar_top-form {
        background: transparent;
      }
      .ctc-logo {
        max-width: 120px;
        margin-bottom: -1rem;
        width: 100%;
        @include breakpoint(lg) {
          max-width: 100%;
        }
      }
    }

    .logo {
      height: auto;

      @include breakpoint(0, xs) {
        width: 40vw;
      }

      @include breakpoint(sm, lg) {
        width: 160px;
      }

      @include breakpoint(lg) {
        position: absolute;
        top: 8px;
        left: 50%;
        transform: translateX(-50%);
      }

      // @include breakpoint(lg, lg) {
      //   width: 200px;
      // }

      @include breakpoint(xl) {
        width: 200px;
      }
    }

    .nav-bar_entries {
      @include breakpoint(0, xs) {
        flex-wrap: wrap;
        justify-content: flex-end;
      }

      p {
        font-weight: $font-weight-medium;
        font-size: 1rem;
        line-height: 145%;
        letter-spacing: 0.03em;
        color: theme('primary');
      }

      & > p {
        font-size: 0.75rem;
      }

      .btn-back {
        @include breakpoint(md, lg) {
          margin-right: 8px;
        }

        @include breakpoint(xl) {
          margin-right: 12px;
        }
      }

      .nav-bar_entries-container {
        display: flex;
        align-items: center;

        @include breakpoint(md, md) {
          margin-left: 4px;
        }

        @include breakpoint(lg) {
          margin-left: 8px;
        }

        & > p {
          margin-left: 4px;
          line-height: 100%;

          @include breakpoint(0, xs) {
            font-size: 9px;
            width: 68px;
          }

          @include breakpoint(sm) {
            text-transform: uppercase;
          }

          @include breakpoint(sm, md) {
            font-size: 10px;
          }

          @include breakpoint(lg) {
            font-size: 10px;
          }
        }

        .nav-bar_entries-counter {
          display: flex;
          align-items: center;
        }

        @include breakpoint(0, xs) {
          margin: 8px 0 0 8px;
        }

        &:nth-of-type(n + 2) {
          @include breakpoint(sm) {
            margin-left: 8px;
          }
        }
      }
  }
    .nav-bar_entries,
    .nav-bar_logout {
      display: flex;
      align-items: center;

      p,
      a {
        @include breakpoint(0, xs) {
          font-size: 12px;
        }

        // @include breakpoint(0, lg) {
        //   font-size: 14px;
        // }
      }

      p {
        margin-bottom: 0;
      }
    }

    .nav-bar_logout {
      @include breakpoint(lg) {
      justify-content: flex-end;
      }

      a {
        font-weight: $font-weight-medium;
        color: $white;
        cursor: pointer;
      }

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include breakpoint(0, xs) {
          max-width: calc(100vw - 260px);
        }

        @include breakpoint(sm, sm) {
          max-width: calc(40vw - 80px);
        }

        @include breakpoint(0, lg) {
          margin-right: 4px;
        }

        @include breakpoint(lg) {
          max-width: 370px;
          margin-right: 8px;
        }
      }
    }
   &.entry-callout {
      justify-content: center;
    }
  }

  &.nav-bar-visible {
    & + .page-landing,
    & + .page-rules {
      & > h1 {
        @include breakpoint(xl) {
          margin: 160px 0 32px;
        }
      }
    }

    & + .page-rules {
      & > h1 {
        @include breakpoint(0, xs) {
          margin: 16px 0 16px;
        }
      }
    }
  }
}
.user-data {
  display: flex;
  color: #000;
  align-items: center;
  justify-content: flex-start;
  font-size: 1rem;
  flex-direction: column;
  font-weight: $font-weight-bold;
  @include breakpoint(576) {
    flex-direction: row;
  }
  @include breakpoint(1430) {
    font-size: 1.2rem;
  }
  .pill {
    background: $hallmark-pink;
    border-radius: 2rem;
    color: #fff;
    display: inline-block;
    font-weight: $font-weight-bold;
    line-height: 1.1;
    margin-top: 4px;
    padding: 0.25rem 0.75rem;
    right: 0.5rem;
    top: 0.5rem;
    margin: 0 .5rem 0 .4rem;

    @include breakpoint(sm) {
      margin-top: 0;
    }

    @include breakpoint(lg) {
      margin: 0 1.5rem 0 1rem;
    }
    .icon {
      max-width: 16px;
      @include breakpoint(sm) {
        max-width: 22px;
      }
    }
  }
  .weekly-prize {
    text-align: center;
    margin-bottom: .25rem;
    @include breakpoint(576) {
      margin-bottom: 0;
    }
  }
  .grand-prize {
    .pill {
      @include breakpoint(lg) {
      margin: 0 0 0 1rem;
      }
    }
  }
}

.sponsor-section {
  color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include breakpoint(lg) {
    flex-direction: row;
  }
  .game-sponsor-logo {
    max-width: 100px;
    @include breakpoint(lg) {
      margin-left: 1rem;
      max-width: 70%;
    }
    @include breakpoint(1430) {
      max-width: 100%;
    }
  }
  span {
    font-size: .6rem;
    margin-bottom: .25rem;
    @include breakpoint(lg) {
      font-size: .8rem;
      margin-bottom: 0;
    }
    @include breakpoint(1430) {
      font-size: 1rem;
    }
    }
  .ctc-logo {
    margin-bottom: -6rem;
    img {
      margin-left: .9rem;
      max-width: 72px;
      transition: max-width .3s linear;

      @include breakpoint(1400) {
        max-width: 100%;
        margin-left: 1.25rem;
      }
    }
  }
}
.hide {
  opacity: 0;
}
.sponsor-group {
  display: flex;
  flex-direction: column;
  text-align: center;
  @include breakpoint(md) {
    top: 50%;
    right: 0;
    position: absolute;
    transform: translateY(-50%);
  }
  .game-sponsor-logo {
    @include breakpoint(md) {
      max-width: 200px;
    }
  }
  span {
    color: #363636;
    text-transform: uppercase;
    font-size: .75rem;
    font-weight: $font-weight-medium;
    letter-spacing: .1rem;
    margin-bottom: .2rem;
  }
}

.nav-bar-visible {
  display: block;
}

.username {
  .icon {
    display: none;
    @include breakpoint(lg) {
      display: inline;
    }
  }
}

.mobile-nav-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
}