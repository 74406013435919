.reward-container {
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  box-shadow: 0 -18px 20px rgba(#DA3732, .1);
  border-radius: 1rem;
  margin-top: 5rem;
  padding: 4rem 1rem;
}
.page-heading {
  padding-top: 1.25rem;
  @include breakpoint(sm) {
    padding-top: 0;
  }
}
.card-reward {
  background: $white;
  border-radius: 2rem;
  border-top-right-radius: 5rem;
  overflow: hidden;
  margin-bottom: 2rem;
  box-shadow: 0 0 10px rgba(0,0,0,.4);
  @include breakpoint(0, xs) {
    border-radius: .5rem;
    margin-left: 2rem;
    margin-bottom: 1rem;
    overflow: visible;
  }
  @include breakpoint(sm) {
    border-radius: 1.5rem;
    border-top-right-radius: 3rem;
  }
  @include breakpoint(md) {
    border-radius: 2rem;
    border-top-right-radius: 5rem;
  }
  .image-holder {
    @include breakpoint(0, xs) {
    left: -30px;
    position: absolute;
    width: 60px;
    border: 1px solid;
    overflow: hidden;
    height: 60px;
    border-radius: 50%;
    border: 2px solid #fff;
    top: 10px;
    }
  img {
    border-bottom-left-radius: 5rem;
    width: 100%;
    @include breakpoint(0, xs) {
      border-radius: 0;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      max-height: 60px;
      max-width: 100px;

    }
    @include breakpoint(sm) {
      border-bottom-left-radius: 3rem;
    }
    @include breakpoint(md) {
      border-bottom-left-radius: 5rem;
    }
  }
}
  .content {
    display: flex;
    flex-direction: column;
    padding: 1.2rem;
    text-align: center;
    @include breakpoint(sm) {
      padding: 1.2rem .75rem;
    }
    @include breakpoint(md) {
      padding: 1.2rem;
    }
    p {
      color: #463C24;
      font-size: 1.4rem;
      line-height: 1.6;
      margin-top: 1rem;
      @include breakpoint(0, xs) {
        font-size: 1rem;
        text-align: left;
        margin-left: 1rem;
        margin-bottom: 0;
      }
      @include breakpoint(sm) {
        font-size: 1rem;
        margin-top: .5rem;
        margin-bottom: .5rem;
      }
      @include breakpoint(md) {
        font-size: 1.4rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
    .icon {
      max-width: 25px;
      margin-right: .25rem;
    margin-top: -3px;    }
  }
  .card-heading {
    color: $ctc-blue;
    font-size: 1.5rem;
    font-weight: 800;
    text-transform: uppercase;
    @include breakpoint(0, xs) {
      font-size: 1.2rem;
      text-align: left;
      margin-left: 1rem;
      margin-top: .5rem;
    }
    @include breakpoint(sm) {
      font-size: 1.2rem;
    }
    @include breakpoint(md) {
      font-size: 1.5rem;
    }
  }
  button {
    margin: 1rem 0;
    @include breakpoint(0, xs) {
      margin-bottom: 0;
    }
  }
  .entry-count {
    font-weight: $font-weight-bold;
  }
}
