.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  &.confetti-repeat {
    @for $i from 0 through 25 {
      .confetti-#{$i} {
        animation-iteration-count: infinite;
      }
    }

    .balloon-1,
    .balloon-2 {
      animation-iteration-count: infinite;
    }
  }

  &.confetti-norepeat {
    @for $i from 0 through 25 {
      .confetti-#{$i} {
        animation-iteration-count: 1;
      }
    }

    .balloon-1,
    .balloon-2 {
      animation-iteration-count: 1;
    }
  }
}

[class|='confetti'] {
  position: absolute;
  z-index: 0;
}

@for $i from 0 through 25 {
  $l: random(100);
  .confetti-#{$i} {
    top: -10%;
    left: unquote($l + '%');
    opacity: random() + 0.5;
    transform: rotate(#{random() * 360}deg);
    animation: drop-#{$i} unquote(4 + random() + 's') unquote(random() + 's');
  }

  @keyframes drop-#{$i} {
    100% {
      top: 110%;
      left: unquote($l + random(15) + '%');
    }
  }
}

.balloon-1 {
  position: absolute;
  top: 180%;
  left: 15%;
  opacity: 1.2512843783;
  -webkit-animation: drop-16 6.6562642767s 0.8517219447s;
  animation: drop-16 6.6562642767s 0.8517219447s;
}

@-webkit-keyframes drop-16 {
  100% {
    top: -50%;
    left: 3%;
  }
}

@keyframes drop-16 {
  100% {
    top: -50%;
    left: 3%;
  }
}
.balloon-2 {
  position: absolute;
  top: 110%;
  left: 70%;
  opacity: 1.0914673292;
  -webkit-animation: drop-17 6.1599449913s 0.1514159789s;
  animation: drop-17 6.1599449913s 0.1514159789s;
}

@-webkit-keyframes drop-17 {
  100% {
    top: -50%;
    left: 89%;
  }
}

@keyframes drop-17 {
  100% {
    top: -50%;
    left: 89%;
  }
}

@media (max-width: 767.98px) {
  @for $i from 0 through 25 {
    $l: random(100);
    .confetti-#{$i} {
      transform: scale(0.5) rotate(#{random() * 360}deg);
    }
  }

  .balloon-2,
  .balloon-1 {
    transform: scale(0.5);
  }
}

@media (min-width: 768px) {
  @for $i from 0 through 25 {
    $l: random(100);
    .confetti-#{$i} {
      transform: rotate(#{random() * 360}deg);
    }
  }
}
