
.badge-container {
    margin-bottom: 1rem;
    @include breakpoint(sm) {
        margin-bottom: 5rem;
    }
    p {
    color: $white;
    text-transform: uppercase;
    margin-top: .5rem;
    font-weight: $font-weight-bold;
    font-size: .6rem;
    letter-spacing: 0;
    max-width: 210px;
    margin: 0 auto;
    margin-top: .5rem;
    text-shadow: 0 0 10px rgba(0,0,0,.3);
    @include breakpoint(sm) {
        font-size: .8rem;
    }
}
}

.badges {
    text-align: center;
    img {
        max-width: 350px;
        opacity: .3;
        max-height: 200px;
    }
    .completed {
        opacity: 1;
        filter: drop-shadow(2px 4px 20px rgba(255,255,255,.4));
    }
}
.medal-count {
    color: $white;
    display: inline-flex;
    font-size: 1.75rem;
    margin-right: 1.5rem;
    font-weight: 500;
    span {
        margin-left: .3rem;
    }
    img {
        max-width: 17px;
        @include breakpoint(md) {
        max-width: 25px;
        }
    }
}
.profile-body {
    padding-top: 2rem;
    .user-data {
        display: flex;
        align-items: center;
        flex-direction: column;
        @include breakpoint(sm) {
        flex-direction: row;
        }
        .nav-bar_logout {
            display: flex;
            justify-content: space-between;
        }
        .username-mobile {
            .username {
                font-size: 1.6rem;
                color: $white;
            }
            button {
                font-weight: 500;
                color: #ffffff;
                font-size: 1.2rem;
                cursor: pointer;
                background: none;
                border: none;
                position: relative;
                padding: 0;
                display: inline-block;
                &:after {
                    content: "";
                    bottom: 4px;
                    background-color: #ffffff;
                    height: 2px;
                    left: 0;
                    position: absolute;
                    width: 100%;
                }
            }
        }
        &.user-data-tickets {
            display: flex;
            margin-top: .5rem;
            flex-direction: row;
            @include breakpoint(md) {

            }
        }
    }
    .profile-data {
        margin-bottom: 1.5rem;
    }
    .user {
        color: $white;
        text-align: left;
        margin-bottom: 0;
    }
    .pill {
        align-self: center;
        background: $white;
        border-radius: 2rem;
        color: $text;
        display: flex;
        font-size: 1rem;
        font-weight: 500;
        margin-left: .25rem;
        padding: .25rem .5rem;
        @include breakpoint(md) {
            font-size: 1.65rem;
            margin-left: 1rem;
            padding: .25rem .75rem;
        }
        .icon {
            margin-right: .25rem;
            @include breakpoint(md) {
                margin-right: .5rem;
            }
        }
    }
    .profile-heading h1{
        color: $white;
        text-transform: uppercase;
        margin-bottom: 2rem;
        font-size: 1.2rem;
        @include breakpoint(md) {
            font-size: 1.4rem;
            margin-bottom: 4rem;
        }
    }
    .badge-data-container {
        display: flex;
        width: 100%;
        margin-top: .5rem;
        @include breakpoint(md) {
            width: auto;
            margin-top: 1rem;
        }
        @include breakpoint(lg) {
            margin-top: 0;
        }
        .badge-data {
            margin-right: 1rem;
        }
        .medal-data {
            display: flex;
            align-items: center;
        }
    }
}

.profile-body  {
    .profile-data {
        display: flex;
        justify-content: space-between;
    }
    .user-data .weekly-prize,
    .user-data .grand-prize {
        display: flex;
    }
    .user-data,
    .medal-data {

        width: 100%;
        @include breakpoint(md) {
            width: auto;
        }
    }

    .badge-data {
        font-size: 1.2rem;
        @include breakpoint(1400) {
        font-size: 1.8rem;
        }

    }
    .weekly-prize,
    .grand-prize {
        font-size: .9rem;
        @include breakpoint(md) {
            font-size: 1.2rem;
        }
        @include breakpoint(1400) {
        font-size: 1.8rem;
        }
    }
.grand-prize {
    margin-left: .55rem;
}
.medal-count {
    font-size: 1.2rem;
    margin-right: .75rem;
    @include breakpoint(1400) {
    font-size: 1.75rem;
    margin-right: 1.5rem;
    }
}
.user-data {
    display: block;
    @include breakpoint(md) {
        display: flex;
    }
}
}