.game-nav {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 20;

  @include breakpoint(0, xs) {
    flex-wrap: wrap;
    align-items: flex-end;
  }

  @include breakpoint(0, sm) {
    top: 16px;
    width: calc(100vw - 24px);
    align-items: center;
  }

  @include breakpoint(md, md) {
    top: 16px;
    width: calc(((1443 / 1920) * 100%) - 80px);
    align-items: center;
  }

  @include breakpoint(lg) {
    align-items: flex-end;
  }

  @include breakpoint(lg, lg) {
    width: calc(((1443 / 1920) * 100%) - 120px);
  }

  @include breakpoint(1280) {
    // top: 6%;
    width: calc(100vw - 648px);
    max-width: 1200px;
  }

  &.game-nav-matching-moments {
    @include breakpoint(0, xs) {
      justify-content: center;
    }

    .game-nav__btn-icon {
      background: theme('primary');
    }

    .game-nav__btn {
      &:focus {
        .game-nav__btn-icon {
          @include focus-outline($navy, 0.3);
        }
      }
    }

    .game-nav__btn-content,
    .game-nav__level {
      color: theme('primary');
    }

    .game-nav__level {
      @include breakpoint(0, sm) {
        margin: 0rem 0.4rem;
      }
    }

    @include breakpoint(lg) {
      margin-top: 32px;
    }

    .game-nav__level {
      @include breakpoint(sm) {
        margin-bottom: 2px;
      }
    }

    .game-nav__btn-content_timer {
      @include breakpoint(0, lg) {
        width: 45px;
      }

      @include breakpoint(xl) {
        width: 85px;
      }
    }

    .game-nav-left {
      @include breakpoint(0, sm) {
        flex: 1;
        justify-content: space-around;
      }
    }

    .game-nav-center {
      @include breakpoint(0, xs) {
        max-width: 70vw;
      }

      @include breakpoint(lg) {
        flex: initial;
      }
    }

    .game-nav-left,
    .game-nav-right {
      flex: initial;

      @include breakpoint(sm, sm) {
        width: 120px;
      }

      @include breakpoint(md, lg) {
        width: 140px;
      }

      @include breakpoint(xl) {
        width: 210px;
      }
    }

    &:nth-of-type(n + 2) {
      @include breakpoint(0, sm) {
        top: 0;
        width: 100%;
      }

      .game-nav-right {
        max-width: 100%;
        margin-left: 0;
        flex-direction: row;
        margin-bottom: 1rem;

        @include breakpoint(0, sm) {
          flex: 1;
          justify-content: space-around;
        }

        .game-nav__btn {
          @include breakpoint(0, sm) {
            padding: 0.1rem 0.2rem !important;
          }
        }
      }
    }
  }

  &.game-nav-fairground-flip {
    .game-nav__btn-icon {
      background: linear-gradient(108.99deg, #fd202f 0%, #e90c1b 49.48%, #d50007 100%);
    }

    .game-nav__btn-content,
    .game-nav__level {
      color: $red;
    }

    .game-nav__btn-content_timer {
      @include breakpoint(0, lg) {
        width: 60px;
      }

      @include breakpoint(xl) {
        width: 100px;
      }
    }

    .game-nav-left,
    .game-nav-right {
      flex: initial;

      @include breakpoint(sm, sm) {
        width: 150px;
      }

      @include breakpoint(md, lg) {
        width: 160px;
      }

      @include breakpoint(xl) {
        width: 240px;
      }
    }

    @include breakpoint(lg, 1279.98) {
      margin-top: 6%;
    }

    @include breakpoint(1280) {
      margin-top: 4.5%;
    }
  }
}

.game-nav-left,
.game-nav-right,
.game-nav-center {
  @include breakpoint(sm) {
    flex: 1;
  }
}

.game-nav-right {
  flex-direction: column;
}

.game-nav-left,
.game-nav-right {
  display: flex;
  position: relative;
  z-index: 15;

  @include breakpoint(0, xs) {
    // justify-content: space-around;
    // width: 100%;
  }

  .game-nav__buttons {
    display: flex;
    align-items: center;

    .game-nav__btn:first-of-type {
      padding-left: 0;
    }
  }
}

.game-nav-center {
  @include breakpoint(0, xs) {
    order: 1;
    width: 100%;
    margin-bottom: 4px;
    text-align: center;
  }

  @include breakpoint(sm, lg) {
    max-width: 340px;
  }

  @include breakpoint(xl) {
    max-width: 400px;
  }

  .game-logo {
    width: 100%;
    height: auto;

    @include breakpoint(0, xs) {
      max-width: 70vw;
    }

    @include breakpoint(sm, md) {
      padding: 0 8px;
    }

    @include breakpoint(lg, lg) {
      padding: 0 8px;
    }

    @include breakpoint(xl) {
      padding: 0 16px;
    }

    @include breakpoint(1200, 1499.98) {
      margin-bottom: 8px;
    }
  }
}

.game-nav-left {
  @include breakpoint(0, xs) {
    order: 2;
    align-items: center;
  }

  @include breakpoint(sm) {
    align-items: flex-start;
    flex-direction: column;
  }

  .game-nav__level {
    background: $white;
    border-radius: 4px;
    font-family: $albra;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    letter-spacing: 0.1rem;

    @include breakpoint(0, xs) {
      font-size: 12px;
      padding: 2px 6px;
    }

    @include breakpoint(sm, lg) {
      font-size: 10px;
      padding: 2px 4px;
    }

    // @include breakpoint(md, md) {
    //   font-size: 14px;
    //   padding: 2px 6px;
    // }

    // @include breakpoint(lg, lg) {
    //   font-size: 10px;
    //   padding: 2px 4px;
    // }

    @include breakpoint(xl) {
      font-size: 16px;
      padding: 4px 8px;
      // margin-bottom: 6px;
      margin-right: 6px;
    }
  }
}

.game-nav-right {
  @include breakpoint(0, xs) {
    order: 3;
    max-width: 360px;
    margin-left: 40px;
  }

  @include breakpoint(sm) {
    align-items: flex-end;
  }

  // @include breakpoint(1500, 0) {
  //   min-width: calc(100% - 400px);
  //   justify-content: flex-end;
  // }

  & > button:nth-of-type(1) {
    padding-left: 0;
  }

  .game-nav__btn {
    display: flex;
    align-items: center;
    padding-right: 0;

    &:focus {
      box-shadow: none;

      .game-nav__btn-icon {
        @include focus-outline($red, 0.3);
      }
    }

    &:hover {
      .game-nav__btn-icon {
        transform: scale(1.05);
      }
    }

    &:active {
      .game-nav__btn-icon {
        transform: scale(0.95);
      }
    }
  }
}

.game-nav__btn {
  display: flex;
  align-items: center;

  &.disabled,
  &:disabled {
    opacity: 0.5;
  }

  &.game-nav__btn-img-hide {
    span > img {
      opacity: 0;
    }
  }

  &.game-nav__btn-img-show {
    span > img {
      opacity: 1;
    }
  }

  span {
    display: inherit;
    align-items: inherit;
  }

  & + a {
    .game-nav__btn:first-of-type {
      @include breakpoint(0, sm) {
        padding-left: 0.2rem !important;
      }

      @include breakpoint(md, 0) {
        padding-left: 0.5rem !important;
      }
    }
  }

  span > img {
    position: absolute;
    left: 50%;
    transition: all 200ms ease-in;
    pointer-events: none;
    transform-origin: 50% 50%;

    @include breakpoint(0, xs) {
      transform: rotate(180deg) translate(50%, 0);
      bottom: 100%;
      animation: arrowHintDown 2s infinite ease-in-out;
      @keyframes arrowHintDown {
        0% {
          transform: rotate(180deg) translate(50%, 0) scale(1);
        }

        10% {
          transform: rotate(180deg) translate(50%, 0) scale(1.1);
        }

        20% {
          transform: rotate(180deg) translate(50%, 0) scale(1);
        }
        30% {
          transform: rotate(180deg) translate(50%, 0) scale(1.1);
        }

        40% {
          transform: rotate(180deg) translate(50%, 0) scale(1);
        }

        100% {
          transform: rotate(180deg) translate(50%, 0) scale(1);
        }
      }
    }

    @include breakpoint(sm, 0) {
      transform: translate(-50%, 0);
      top: 100%;
      animation: arrowHintUp 2s infinite ease-in-out;
      @keyframes arrowHintUp {
        0% {
          transform: translate(-50%, 0) scale(1);
        }

        10% {
          transform: translate(-50%, 0) scale(1.1);
        }

        20% {
          transform: translate(-50%, 0) scale(1);
        }
        30% {
          transform: translate(-50%, 0) scale(1.1);
        }

        40% {
          transform: translate(-50%, 0) scale(1);
        }

        100% {
          transform: translate(-50%, 0) scale(1);
        }
      }
    }
  }

  &:not(button) {
    cursor: default !important;
  }

  @include breakpoint(0, sm) {
    padding: 0.1rem 0.2rem;
  }

  @include breakpoint(md, 0) {
    padding: 0.1rem 0.5rem;
  }

  .game-nav__btn-icon {
    border-radius: 50%;
    position: relative;
    z-index: 5;
    transition: all 200ms ease-in;

    @include breakpoint(0, xs) {
      width: 34px;
      height: 34px;
      min-width: 34px;
    }

    @include breakpoint(sm, lg) {
      width: 30px;
      height: 30px;
      min-width: 30px;
    }

    // @include breakpoint(630, 0) {
    //   width: 32px;
    //   height: 32px;
    // }

    @include breakpoint(xl) {
      width: 40px;
      height: 40px;
      min-width: 40px;
    }

    img {
      width: 100%;
    }
  }

  .game-nav__btn-content {
    background: $white;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
    font-family: $albra;
    font-weight: $font-weight-bold;

    @include breakpoint(0, xs) {
      margin-left: -15px;
      font-size: 12px;
      padding: 4px 8px 4px 17px;
      border-radius: 4px;
    }

    @include breakpoint(0, lg) {
      margin-left: -15px;
      font-size: 10px;
      padding: 2px 6px 2px 18px;
      border-radius: 4px;
    }

    @include breakpoint(xl) {
      margin-left: -20px;
      font-size: 20px;
      padding: 4px 14px 4px 24px;
      border-radius: 8px;
    }

    // @include breakpoint(1200, 1499.98) {
    //   margin-left: -16px;
    //   font-size: 14px;
    //   padding: 4px 8px 4px 20px;
    //   border-radius: 4px;
    // }

    // @include breakpoint(1500, 0) {
    //   margin-left: -20px;
    //   font-size: 20px;
    //   padding: 4px 14px 4px 24px;
    //   border-radius: 8px;
    // }

    &.game-nav__btn-content_shuffles,
    &.game-nav__btn-content_hints {
      @include breakpoint(0, sm) {
        width: 38px;
      }
      @include breakpoint(md) {
        width: 54px;
      }
      // @include breakpoint(1200, 1499.98) {
      //   width: 38px;
      // }
      // @include breakpoint(1500, 0) {
      //   width: 54px;
      // }
    }
  }
}
