@mixin breakpoint($min: 0, $max: 0) {
  $known-breakpoints: xs, sm, md, lg, xl;

  @if not index($known-breakpoints, $min) and type-of($min) != number {
    @error "Unknown breakpoint #{$min}.";
  }

  @if not index($known-breakpoints, $max) and type-of($max) != number {
    @error "Unknown breakpoint #{$max}.";
  }

  @if $min == 0 and $max == 0 {
    @error "Invalid breakpoints.";
  }

  $query: 'all';
  $minValue: 0;
  $maxValue: 0;

  $minBreakpoints: (
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
  );

  $maxBreakpoints: (
    xs: 575.98px,
    sm: 767.98px,
    md: 991.98px,
    lg: 1199.98px,
  );

  @if type-of($min) == string {
    $minValue: map-get($minBreakpoints, $min);
  } @else if type-of($min) == number {
    $minValue: #{$min}px;
  }

  @if type-of($max) == string {
    $maxValue: map-get($maxBreakpoints, $max);
  } @else if type-of($max) == number {
    $maxValue: #{$max}px;
  }

  @if $max == 0 {
    $query: '(min-width: #{$minValue})';
  } @else if $min == 0 {
    $query: '(max-width: #{$maxValue})';
  } @else {
    $query: '(min-width: #{$minValue}) and (max-width: #{$maxValue})';
  }

  @media #{$query} {
    @content;
  }
}
