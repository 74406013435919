.text-field {
  position: relative;
  font-family:$albra;

  .input-label {
    color: #292929;
    margin-left: 4px;
    font-weight: $font-weight-bold;
    margin-left: 1rem;

    @include breakpoint(0, md) {
      font-size: 12px;
    }

    @include breakpoint(lg) {
      font-size: 14px;
    }
  }

  input {
    width: 100%;
    background: $white;
    border: 2px solid #292929;
    box-sizing: border-box;
    border-radius: 4rem;
    color: $text;

    &::placeholder {
      //   color: $input-placeholder;
      //   opacity: 1;
    }

    @include breakpoint(0, md) {
      height: 52px;
    }

    @include breakpoint(lg) {
      height: 52px;
      border: 2px solid #292929;
    }
  }
  .form-select {
    height: 52px;
    border: 2px solid #292929;
    border-radius: 4rem;
    color: #463C24;
    &.is-invalid {
      border-color: #e21219;
    }
  }
  .form-control:focus {
    border-color: $input-outline;
    box-shadow: 0 0 0 0.2rem rgba(33, 134, 73, 0.1);
  }

  .form-control.is-invalid,
  .was-validated .form-control:invalid {
    border-color: $hallmark-red;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url('../../images/icons/form-error.svg') !important;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center !important;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
    border-radius: 3rem;
  }

  .form-control.is-valid,
  .was-validated .form-control:valid {
    // border-color: $ctc-green;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url('../../images/icons/form-valid.svg') !important;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center !important;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
  }

  .valid-tooltip {
    display: none !important;
  }

  .invalid-tooltip {
    border-radius: 0.2rem;
    margin-top: .1rem;
  }
}

.invalid-tooltip {
  font-size: 11px;
  padding: 1px 6px;
  background-color: $red;
  margin: 0;
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: black;

  @include breakpoint(0, xs) {
    margin-bottom: 22px;
  }
  @include breakpoint(sm, md) {
    margin-bottom: 32px;
  }

  @include breakpoint(lg, 0) {
    margin-bottom: 24px;
  }

  a:hover {
    @media (hover: hover) {
      text-decoration: underline;
    }
  }

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      &:after {
        opacity: 1;
      }
    }
  }

  & ~ .error-message {
    left: 50px;
    top: 65px;
  }

  &:first-of-type {
    margin-top: 32px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 28px;
    border: 2px solid $hallmark-pink;
    background-color: $white;
    box-sizing: border-box;

    &:after {
      content: '';
      position: absolute;
      opacity: 0;
      left: 7px;
      top: 0px;
      width: 9px;
      height: 18px;
      border-style: solid;
      border-color: $hallmark-pink;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      transition: opacity 200ms ease-out;
    }
  }

  @media (hover: hover) {
    &:hover input[type='checkbox'] ~ .checkmark {
      background: theme('linkFocus');
    }
  }

  .invalid-tooltip {
    display: block;
    border-radius: 0px 0 0.2rem 0.2rem;

    @include breakpoint(0, xs) {
      top: calc(100% + 7px);
    }

    @include breakpoint(lg, 0) {
      top: calc(100% + 4px);
    }
  }

  &.checkbox-error {
    .checkmark {
      border-color: $hallmark-red;
    }
  }
}
.sponsor-callout {
  padding-left: 40px;
}
.desktop-break {
  display: none;
  @include breakpoint(1459) {
  display: block;
  }
}
.remove-image {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: $font-weight-bold;
  margin-top: 1rem;
  .btn-delete {
    color: $hallmark-pink;
    text-decoration: underline;
  }
}