@mixin font-face($font-family, $file-path, $font-weight, $font-style) {
  @font-face {
    font-family: $font-family;
    src: url('#{$file-path}.woff') format('woff'),
          url('#{$file-path}.otf') format('opentype');
   font-weight: $font-weight;
   font-style: $font-style;
  }
}

@include font-face(AlbraGrotesk, '../../fonts/Albra-Grotesk-Light', 100, normal);
@include font-face(AlbraGrotesk, '../../fonts/Albra-Grotesk-Regular', 400, normal);
@include font-face(AlbraGrotesk, '../../fonts/Albra-Grotesk-Medium', 500, normal);
@include font-face(AlbraGrotesk, '../../fonts/Albra-Grotesk-Bold', 700, normal);
@include font-face(AlbraGrotesk, '../../fonts/Albra-Grotesk-Black', 800, normal);

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v115/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}