.group {
  .card-groups {
    background: $white;
    box-shadow: 0 0 20px rgba(0, 0, 0, .3);
    border-radius: 1rem;
    padding: 1.5rem 1rem 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    @include breakpoint(md) {
      margin-top: 2.5rem;
    }
    .btn {
      margin-bottom: 0.5rem;
    }
  }
  .group-name {
    color: $ctc-blue;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    font-size: 1.3rem;
    text-align: center;
    line-height: 1.2;
    letter-spacing: 0;
    margin-bottom: .5rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 48px;
  }
  .medal-count {
    color: $ctc-blue;
    margin-right: 1.25rem;
  }
  .medal-data {
    display: flex;
    justify-content: center;
    font-weight: $font-weight-bold;
    img {
      margin-right: .5rem;
    }
  }
  .icon-header {
    position: absolute;
    top: -70px;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    left: 0;
    .icon {
      max-width: 140px;
      margin: 0 auto;
      left: 0;
      right: 0;
    }
  }
  .button-group {
    display: flex;
    justify-content: space-around;
    margin-top: .5rem;

    button {
      width: 100%;
    }
    &.button-action{
      .btn {
        font-size: 1rem;
      }
    }
  }
  .ticket-count {
    color: $ctc-blue;
    display: flex;
    font-size: 1.75rem;
    justify-content: center;
    align-items: center;
    font-weight: 500;
  }
}
.group-header {
  h1 {
  color: $white;
  text-align: left;
  text-transform: uppercase;
  font-size: 1.1rem;
  @include breakpoint(md) {
    font-size: 2rem;
  }
}
button {
  float: right;
  padding: 0.5rem 0.75rem;
  @include breakpoint(md) {
  padding: 0.5rem 1.25rem;
  }
}
}

.create-group {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  @include breakpoint(md) {
    display: block;
  }
  .input-label {
    color: $white;
  }
  .text-field-input {
    width: 100%;
    margin-bottom: 1rem;
    display: inline-block;
    @include breakpoint(md) {
      width: 49%;
      margin-bottom: 0;
    }
  }
  .btn.btn-icon-after {
    font-size: .9rem;
    @include breakpoint(md) {
    display: inline-block;
    width: 49%;
    margin-left: 2%;
    }
    @include breakpoint(xl) {
      font-size: 1.4rem;
    }
  }
  .checkbox-container {
    margin-top: .75rem;
    p {
      color: $white;
      span {
        font-weight: $font-weight-bold;
      }
    }
    .checkmark {
      background-color: transparent;
      border-color: $white;
      margin-top: -3px;
      &:after {
        border-color: $white;
      }
    }
  }
}
.group-header {
  margin-top: 4rem;
}

.copy-link {
  display: flex;
  margin-bottom: 2rem;
  .share-link__input {
    display: none;
    @include breakpoint(sm) {
      display: inline;
    border-radius: .75rem 0 0 .75rem;
    border: 1px solid #B3B3B3;
    padding: .75rem 0 .75rem 1.5rem;
    width: 80%;
    }
    &:focus-visible {
      outline: none;
    }
  }
}

ul.pagination {
  margin-top: 2rem;
  justify-content: center;
}


.page-item {
  height: 50px;
  width: 50px;
  button {
    padding: .375rem .75rem;
  }
  &.number {
    margin: 0 1rem;
  }
}
.page-link {
  background: transparent;
  border-radius: .25rem;
  height: 100%;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  &.next,
  &.previous {
    background-color: $white;
  }
  .icon-arrow-previous {
    transform: rotate(180deg);
  }
  &:disabled {
    opacity: .2;
  }
}
.my-masonry-grid {
  display: flex;
}