
.banner-bottom {
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 999;
  @media (min-width: 992) {
    bottom: 0
  }

  @media (max-width: 1280px) {
    transform: translateX(-160px) translateY(-100%) translate3d(0, 100%, 0);
    // width: 320px;
    // height: 100px;
    // background: #e1e1e1;
  }

  @media (min-width: 1281px) {
    transform: translateX(-364px) translateY(-100%) translate3d(0, 100%, 0);
    // width: 728px;
    // height: 90px;
    // background: #e1e1e1;
  }

  #_fw_ad_container_html_banner_ad_ {
    display: flex !important;
  }

  &.fadeOutDown {
    animation-iteration-count: 1;
    animation-duration: 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-duration: 1s;
  }
}

.banner-right {
  @media (min-width: 1280px) {
    position: absolute;
    right: 0;
    z-index: 80;
    // width: 300px;
    // height: 600px;
    // background: #eae7f1;

    &.banner-right-no-nav {
      top: calc(64px + 24px);
    }

    &.banner-right-with-nav {
      top: calc(64px + 55px + 24px);
    }
  }
}

.banner-footer {
  width: 100%;
  text-align: center;
  background-color: #333;
}

.no-menu {
  .banner-bottom {
    bottom: 0;
  }
}
