.row-tight {
> *{
  padding-right: calc(var(--bs-gutter-x)/ 4);
  padding-left: calc(var(--bs-gutter-x)/ 4);
}
&-sm {
  @include breakpoint(0, xs) {
    > *{
      padding-right: calc(var(--bs-gutter-x)/ 4);
      padding-left: calc(var(--bs-gutter-x)/ 4);
    }
  }
}
}
.row {
   [class^='col'] {
    flex-shrink: 0;
    padding-right: calc(var(--bs-gutter-x)/ 2);
    padding-left: calc(var(--bs-gutter-x)/ 2);
    margin-top: var(--bs-gutter-y);
  }
}