.how-to-play {
    background: $white;
    border-radius: 1rem;
    max-width: 950px;
    margin: 0 auto;
    padding: 2rem;
    box-shadow: 0 0 20px rgba(0,0,0,.3);
    @include breakpoint(0, xs) {
        padding: 0;
    }
    p {
        color: $text;
        font-size: 1.2rem;
        @include breakpoint(0, xs) {
            font-size: 1.1rem;
        }
    }
    .text-bold {
        font-weight: 800;
    }
    h2 {
        color: #BA0020;
        font-size: 1.6rem;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        margin-bottom: 0;
        text-align: left;
    }
    .rules-row {
        display: flex;
        align-items: center;
        margin: 1.5rem 0;
    }
.rules-link {
    color: $ctc-red;
}
li p {
    margin-bottom: 0;
}
}

.section-header {
    padding: 1.5rem 0;
    @include breakpoint(0, xs) {
        padding: 1rem 0 .5rem;
    }
    p {
        text-align: center;
        color: #4F4F4F;
        font-size: 1.2rem;
        max-width: 550px;
        margin: 0 auto;
        margin-top: 1.2rem;
        margin-bottom: 1rem;
        }
}
.external-link {
    color: $white;
    padding: 2rem 0 0;
    font-size: 1.2rem;
    @include breakpoint(0, xs) {
        font-size: .95rem;
        padding: 2rem 0 2rem;
    }
    a {
        color: $white;
        position: relative;
        &:after {
            background-color: $white;
            bottom: -.25rem;
            content: '';
            height:2px;
            left: 0;
            position: absolute;
            width: 100%;

        }
    }
}