html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;

  min-height: 100%;
  font-family: $albra;
  overflow-x: hidden;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#page-container {
  flex-grow: 1;
  @include breakpoint(0, sm) {
    padding-top: 16px;
  }
}

.full-screen-page {
  flex: 1;
  transition: background 700ms ease-out;
}

#content-wrap {
  background: url(https://1240369105.rsc.cdn77.org/loveuary/static/background-texture-mobile.png), linear-gradient(180deg, rgba(246,225,239,1) 0%, rgba(231,192,217,0) 100%);
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  padding-bottom: 0;

  @include breakpoint(lg) {
    padding-bottom: 56px;
    min-height: 1000px;
    background-position: 0 0;
  }
  @include breakpoint(576) {
    background: url(https://1240369105.rsc.cdn77.org/loveuary/static/background-texture-tablet.png), linear-gradient(180deg, rgba(246,225,239,1) 0%, rgba(231,192,217,0) 100%);
    background-repeat: no-repeat;
  }
  @include breakpoint(769) {
    background: url(https://1240369105.rsc.cdn77.org/loveuary/static/background-texture.png), linear-gradient(180deg, rgba(246,225,239,1) 0%, rgba(231,192,217,0) 100%);
    background-repeat: no-repeat;
  }
}

#footer {
  flex-shrink: 0;
  z-index: 20;
}
