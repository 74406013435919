.fair-background {
  position: relative;
  pointer-events: none;

  @include breakpoint(lg) {
    z-index: 1;
  }

  &.fair-background-form {
    @include breakpoint(lg, lg) {
      margin-top: -20vw;
    }

    @include breakpoint(xl) {
      margin-top: -25vw;
    }
  }

  .bg-el_fair {
    width: 100vw;
    height: auto;
    position: relative;
    z-index: 2;
  }

  .bg-el_stalls,
  .bg-el_people {
    position: absolute;
    width: 100%;
    height: auto;
    left: 0;
    top: 0;
  }

  .bg-el_stalls {
    z-index: 3;
  }

  .bg-el_people {
    z-index: 4;
  }
}

.bg-el_cloud,
.bg-el_balloon {
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

.page-form {
  .bg-el_balloon-red {
    @include breakpoint(0, xs) {
      top: calc((150 / 1267) * 100%);
      opacity: 0.5;
    }
  }
}

.bg-el_balloon-red {
  top: calc((250 / 1267) * 100%);
  z-index: 2;

  @include breakpoint(0, xs) {
    width: calc((36 / 375) * 100%);
    left: calc((8 / 375) * 100%);
  }

  @include breakpoint(sm, md) {
    width: calc((36 / 575) * 100%);
    left: calc((16 / 575) * 100%);
  }

  @include breakpoint(lg) {
    width: calc((57 / 1920) * 100%);
    left: calc((112 / 1920) * 100%);
  }
}

.bg-el_balloon-grass {
  top: calc((190 / 1267) * 100%);
  z-index: 3;

  @include breakpoint(0, xs) {
    width: calc((46 / 375) * 100%);
    left: calc((307 / 375) * 100%);
  }

  @include breakpoint(sm, md) {
    width: calc((46 / 575) * 100%);
    left: calc((450 / 575) * 100%);
  }

  @include breakpoint(lg) {
    width: calc((72 / 1920) * 100%);
    left: calc((1630 / 1920) * 100%);
  }
}

.bg-el_balloon-sky {
  top: calc((160 / 1267) * 100%);
  z-index: 2;

  @include breakpoint(0, xs) {
    left: calc((356 / 375) * 100%);
    width: calc((30 / 375) * 100%);
  }

  @include breakpoint(sm, md) {
    left: calc((500 / 575) * 100%);
    width: calc((30 / 575) * 100%);
  }

  @include breakpoint(lg) {
    left: calc((1700 / 1920) * 100%);
    width: calc((45 / 1920) * 100%);
  }
}

.bg-el_cloud-0 {
  animation: cloud 40s infinite 0s ease-in-out;
  top: calc((100 / 1267) * 100%);
  z-index: 1;

  @include breakpoint(0, xs) {
    width: calc((85 / 375) * 100%);
    left: calc((50 / 375) * 100%);
  }

  @include breakpoint(sm, md) {
    width: calc((85 / 575) * 100%);
    left: calc((50 / 575) * 100%);
  }

  @include breakpoint(lg) {
    width: calc((130 / 1920) * 100%);
    left: calc((160 / 1920) * 100%);
  }
}

.bg-el_cloud-1 {
  animation: cloud 40s infinite 0s ease-in-out;
  top: calc((110 / 1267) * 100%);
  z-index: 1;

  @include breakpoint(0, xs) {
    width: calc((110 / 375) * 100%);
    left: calc((220 / 375) * 100%);
  }

  @include breakpoint(sm, md) {
    width: calc((110 / 575) * 100%);
    left: calc((220 / 575) * 100%);
  }

  @include breakpoint(lg) {
    width: calc((170 / 1920) * 100%);
    left: calc((410 / 1920) * 100%);
  }
}

.bg-el_cloud-2 {
  animation: cloud 40s infinite 0s ease-in-out;
  left: calc((1410 / 1920) * 100%);
  top: calc((240 / 1267) * 100%);
  z-index: 1;

  @include breakpoint(0, md) {
    display: none;
  }

  @include breakpoint(lg) {
    width: calc((520 / 1920) * 100%);
  }
}

.bg-el_balloon {
  animation: balloon 7s infinite ease-in-out;

  @keyframes balloon {
    0% {
      transform: translate(0, 0);
    }
    30% {
      transform: translate(0, -0.5rem);
    }
    50% {
      transform: translate(0, -0.3rem);
    }
    70% {
      transform: translate(0, -0.5rem);
    }
    100% {
      transform: translate(0, 0);
    }
  }
}

.bg-el_cloud {
  z-index: 1;

  @keyframes cloud {
    0% {
      transform: translate(0, 0);
    }
    48% {
      transform: translate(-100vw, 0);
      opacity: 1;
    }
    49% {
      transform: translate(-100vw, 0);
      opacity: 0;
    }
    50% {
      transform: translate(100vw, 0);
      opacity: 0;
    }
    51% {
      transform: translate(100vw, 0);
      opacity: 1;
    }
    100% {
      transform: translate(0, 0);
    }
  }
}

.page-matching-moments {
  background: #8fb208;
  flex: 1;
}

.page-game-matching-moments {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;


  @include breakpoint(0, xs) {
    background-size: 120% 100%, 45% auto, 60% auto, 30% auto, 50% auto, cover;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-position: top center, top left, top right, bottom left, bottom right, center center;
  }

  @include breakpoint(sm, sm) {
    background-size: 120% calc(100% - 90px), 45% auto, 60% auto, 30% auto, 50% auto, cover;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-position: top center, top left, top right, bottom left, bottom right, center center;
  }

  @include breakpoint(md, md) {
    background-size: 90% calc(100% - 76px), 45% auto, 60% auto, 30% auto, 50% auto, cover;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-position: top center, top left, top right, bottom left, bottom right, center center;
  }

  @include breakpoint(lg, 1279.98) {
    background-size: 75% calc(100% - 64px), 45% auto, 60% auto, 30% auto, 50% auto, cover;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-position: top center, top left, top right, bottom left, bottom right, center center;
  }

  @include breakpoint(1280) {
    background-size: calc(100vw - 500px) calc(100% - 50px), 45% auto, 60% auto, 30% auto, 50% auto, cover;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-position: top center, top left, top right, bottom left, bottom right, center center;
  }

  .game-container canvas {
    @include breakpoint(0, xs) {
      margin-bottom: -10vw;
    }
  }
}
