.result-container {
  position: relative;
  z-index: 5;
  border-radius: 0.5rem;

  @include breakpoint(0, xs) {
  }

  @include breakpoint(lg, 1279.98) {
    max-width: 615px;
  }

  @include breakpoint(1280) {
    width: 615px;
    max-width: calc(100vw - 648px);
  }
  h1 {
    color: $white;
  }
  .score {
    @include breakpoint(0, xs) {
      padding: 1rem;
    }
  }
}
.question-header {
  h1 {
    text-align: center;
  }
}

.row-results {
  background: #fdfafa;
  border-radius: 0.25rem;
  position: relative;
  box-shadow: 0 0 38px rgba(#000, 0.3);
  padding-bottom: 1.5rem;
  h1 {
    color: $ctc-blue;
    text-transform: uppercase;
    text-align: left;
    @include breakpoint(0, xs) {
      text-align: center;
    }
  }
  .score-callout {
    color: #c10124;
    font-weight: 800;
    margin: 0 0.5rem;
  }
  .btn-primary,
  .btn-secondary {
    width: 100%;
  }
  .btn-secondaty {
    position: relative;
    .icon {
      position: absolute;
      left: 1rem;
      top: calc(50% - 12px);
    }
  }
  @include breakpoint(sm, md) {
    padding: 16px;
  }
  @include breakpoint(lg) {
    padding: 40px;
    padding-top: 20px;
  }
  .btn-group {
    display: flex;
    margin-top: 0.5rem;
  }
  .bonus-entry-count {
    border: 1px solid #dfdfdf;
    border-radius: 3rem;
    font-size: 1.5rem;
    font-weight: $font-weight-bold;
    padding: 0.25rem 0.75rem;
    max-width: 166px;
    text-align: center;
    margin-top: 1rem;
    margin-right: 1rem;
    position: absolute;
    top: 0.75rem;
    right: .1rem;
    @include breakpoint(0, xs) {
      margin: 0 auto;
      margin-top: 1rem;
    }
    .icon {
      max-width: 30px;
    }
  }
  .prize-img {
    text-align: center;
    padding-top: 2rem;
    margin-bottom: 1rem;
    @include breakpoint(0, xs) {
      text-align: center;
      margin-top: 2rem;
    }
    img {
      max-width: 250px;
    }
  }
  .prize-info-retry {
    margin-top: 40px;
  }
  .prize-info {
    display: flex;
    justify-content: center;
    @include breakpoint(0, xs) {
      text-align: center;
      justify-content: center;
    }
    p {
      color: #000000;
    }
    .score-callout {
      color: $ctc-red;
    }
    .score-result-copy {
      font-size: 1.2rem;
      text-align: center;
      @include breakpoint(sm) {
        font-size: 1.6rem;
      }
    }
  }
  &.share {
    font-size: .8rem;
    text-transform: uppercase;
    color: #347aa7;
    font-weight: 700;
    padding: 1rem;
    @include breakpoint(sm) {
      font-size: 1.3rem;
      margin-top: 1.5rem;
      padding: 1rem 2.5rem;
    }
    .social-share {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .banner-complete img {
    position: absolute;
    top: -1rem;
    left: calc(50% - 125px);
  }
  .bonus-entry-copy {
    color: $ctc-red;
    font-weight: 700;
    span {
      margin: 0 0.5rem;
    }
  }
}
