.page-error {
  @extend .full-screen-page;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include breakpoint(0, xs) {
    margin: 16px 16px 24px 16px;
  }

  @include breakpoint(xl) {
    margin: 180px 0;
  }

  h1 {
    margin-bottom: 2rem;
  }
}
