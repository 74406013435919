.page-rules {
  padding-bottom: 2rem;
  padding-top: 2rem;
  margin-top: -1rem;
  @include breakpoint(sm) {
    padding-top: 0;
    margin-top: 0;
  }
  h4 {
    color: $navy;
    text-align: center;
    font-weight: $font-weight-bold;
    font-size: 18px;
  }

  h1,
  h4 {
    padding: 0 16px;
  }

  .logo {
    display: block;
    height: auto;

    @include breakpoint(0, sm) {
      width: 150px;
      margin: 16px auto;
    }

    @include breakpoint(md) {
      width: 250px;
      margin: 32px auto;
    }
  }

  ol {
    margin: 3em 0;
    li {
      p {
        color: $text;

        a {
          text-decoration: underline;
          word-break: break-word;
        }
      }
    }
  }
}

table, tr,td, th {
border: 1px solid #000;
font-size: .45rem;
padding: .05rem;
line-height: 1.4;
@include breakpoint(sm) {
  font-size: .7rem;
}
@include breakpoint(md) {
  padding: 1rem;
  font-size: .8rem;
}
}
.table-row-header {
  text-align: center;
}
table {
  margin: 1rem auto;
  width: 100%;
  @include breakpoint(md) {
    margin: 2rem auto;
  }
}