::-moz-selection {
  background: $ctc-blue;
  color: $white;
}

::selection {
  background: $ctc-blue;
  color: $white;
}

.header {
  font-weight: $font-weight-bold;
  line-height: 115%;
  text-align: center;
  letter-spacing: 0.03em;
  color: theme('primary');
}

.gotham-uppercase {
  font-family: $albra;
  font-weight: $font-weight-medium;
  line-height: 110%;
  text-align: center;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: theme('primary');
}

.grass {
  color: $grass;
}

.red {
  color: $red;
}

a {
  font-family: $albra;
  transition: all 300ms ease-in;
}

a:hover {
  text-decoration: none;
}

.centered {
  text-align: center !important;
}

.text-medium {
  font-weight: $font-weight-medium;
}

.bold {
  font-weight: $font-weight-bold;
}

.highlighted {
  color: $grass;
}

.small {
  font-size: 80%;
}

.big {
  font-size: 110%;
}

.light {
  color: $sky;
}

h1 {
  @extend .header;

  @include breakpoint(0, xs) {
    font-size: 1.4rem;
  }

  @include breakpoint(xl) {
    font-size: 42px;
  }
}

h2 {
  font-family: $albra;
  font-weight: $font-weight-bold;
  line-height: 140%;
  text-align: center;
  color: theme('primary');

  @include breakpoint(0, xs) {
    font-size: 2rem;
  }

  @include breakpoint(xl) {
    font-size: 3rem;
  }
}

h3 {
  font-family: $albra;
  color: $red;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  letter-spacing: 0.03em;

  @include breakpoint(0, xs) {
    font-size: 1.4rem;
  }
}

h4.modal-subheader {
  font-family: $albra;
  color: theme('primary');
  font-weight: $font-weight-book;
  line-height: 150%;
  margin-bottom: 2rem;
}

p {
  font-family: $albra;
  font-weight: $font-weight-book;
  font-size: 1rem;
  line-height: 140%;
}

.uppercase {
  @extend .gotham-uppercase;
}

a {
  color: $sky-ada;
  text-decoration: none;

  &:hover {
    color: $sky-ada;
    text-decoration: none;
  }
}

//   p {
//     line-height: 150%;
//     font-family: $gotham;
//     color: $text;

//     @media (max-width: $bp-xs-max) {
//       font-size: 14px;
//     }
//   }

//   h1 {
//     font-family: $gotham;
//     color: $text;
//     font-weight: $font-weight-bold;
//     text-transform: uppercase;
//     letter-spacing: 0.03em;

//     @include breakpoint(sm, sm) {
//       font-size: 1.8rem;
//     }

//     @include breakpoint(md, lg) {
//       font-size: 2rem;
//     }

//     @media (min-width: 1440px) {
//       font-size: 2.1rem;
//     }
//   }

//   h2 {
//     font-family: $gotham;
//     color: $text;
//     font-weight: $font-weight-book;
//     line-height: 160%;

//     @include breakpoint(0, sm) {
//       font-size: 1.2rem;
//     }

//     @include breakpoint(md, md) {
//       font-size: 1.4rem;
//     }

//     @include breakpoint(lg, lg) {
//       font-size: 1.6rem;
//     }

//     @media (min-width: 1440px) {
//       font-size: 26px;
//     }
//   }

//   h3 {
//     font-family: $gotham;
//     color: theme('link');
//     font-weight: $font-weight-bold;
//     text-transform: uppercase;
//     letter-spacing: 0.03em;

//     @include breakpoint(0, xs) {
//       font-size: 1.4rem;
//     }
//   }

//   h4 {
//     font-family: $gotham;
//     color: $text;
//     font-weight: $font-weight-book;
//     line-height: 150%;

//     @media (max-width: $bp-xs-max) {
//       font-size: 1.1rem;
//     }
//   }

//   h5 {
//     font-family: $gotham;
//     color: $text;
//     font-weight: $font-weight-book;
//     line-height: 150%;

//     @media (max-width: $bp-xs-max) {
//       font-size: 1.1rem;
//     }
//   }

//   h6 {
//     font-family: $gotham;
//     letter-spacing: 0.11em;
//     text-transform: uppercase;
//     color: $text;
//   }

//   .copy-desktop {
//     @media (max-width: $bp-xs-max) {
//       display: none;
//     }
//   }

//   a {
//     font-family: $gotham;
//     transition: all 300ms ease-in;
//   }

//   a:hover {
//     text-decoration: none;
//   }

//   .centered {
//     text-align: center !important;
//   }

//   .text-medium {
//     font-weight: $font-weight-medium;
//   }

//   .bold {
//     font-weight: $font-weight-bold;
//   }

//   .text-black {
//     font-weight: $font-weight-bold;
//   }

//   .link {
//     color: theme('link');
//   }

//   .light {
//     color: $text-light;
//   }

//   .uppercase {
//     text-transform: uppercase;
//   }

//   .gold {
//     color: $ctc-gold;
//   }

//   a {
//     color: theme('link');

//     &:hover {
//       @media (hover: hover) {
//         color: theme('linkHover');
//       }

//       @media (hover: none) {
//         color: theme('link');
//       }
//     }
//   }
