.game_stall-background {
  & > svg {
    &.game_stall {
      z-index: 1;
    }

    &:not(.game_stall) {
      &:nth-of-type(1) {
        z-index: 3;
      }

      &:nth-of-type(2) {
        z-index: 4;
      }
    }
  }

  .spin-to-win_stall {
    z-index: 2 !important;

    .wheel-body {
      z-index: 5;
    }

    .spin-indicator {
      z-index: 7;
    }

    .wheel-spinning-lights {
      z-index: 6;
    }

    canvas {
      z-index: 4;
    }

    .spin-button {
      z-index: 8;
    }

    .remaining-spins_carnie {
      z-index: 10;
    }
  }
}
