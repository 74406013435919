.disclaimer {
  z-index: 5;
  width: 100%;
  left: 0;

  @include breakpoint(0, sm) {
    position: relative;
    margin-top: -1px;
  }

  @include breakpoint(lg) {
    position: absolute;
    bottom: 0;
  }

  p {
    color: #292929;
    display: block;
    font-size: 8px;
    font-weight: $font-weight-light;
    margin: 0 auto;
    max-width: 1120px;
    padding: 8px;
    transition: all 500ms ease-in;


    @include breakpoint(370, 0) {
      font-size: 9px;
      padding: 16px;
    }

    @include breakpoint(0, xs) {
      text-align: left;
    }

    @include breakpoint(xl) {
      text-align: center;
    }

    a {
      color: inherit;
      text-decoration: underline;
      white-space: nowrap;
    }
  }

  &.disclaimer-watch-and-win {
    p {
      color: $white;
    }
  }


  &.disclaimer-fairground-flip,
  &.disclaimer-spin-to-win {
    @include breakpoint(0, sm) {
      // background-color: #e2a383;
      margin-top: -40px;
    }

    @include breakpoint(md) {
      position: absolute;
      bottom: 0;
    }

    p {
      @include breakpoint(md) {
        width: calc((1400 / 1920) * 100%);
      }
    }
  }

  &.disclaimer-watch-and-win {
    @include breakpoint(0, md) {
      background-color: #2b4e00;
    }
  }

  &.disclaimer-matching-moments {
    @include breakpoint(0, xs) {
      background-color: #8fb208;
      padding-top: 16px;
    }

    @include breakpoint(sm, md) {
      position: absolute;
      bottom: 0;
    }
  }
}
.score-info-header {
  font-size: 1rem;
  margin-bottom: .5rem;
  @include breakpoint(md) {
    margin-bottom: 2rem;
    font-size: 1.6rem;
  }
  &.repeat {
    font-size: 1.5rem;
    margin-bottom: 0;
    color: #463C24;
    font-weight: $font-weight-bold;

    @include breakpoint(md) {
      font-size: 2.2rem;
    }
  }
}

