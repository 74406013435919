.map {
  position: relative;
}

.map-container {
  filter: drop-shadow(0px 12px 22px rgba(73, 0, 22, 0.18));
  position: relative;
  width: 100%;
}

.map-pins-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.map-item {
  position: absolute;
  z-index: 10;
  transform: translateX(-50%);
}

.map-marker {
  position: relative;
  bottom: 0;
  transform: translateY(-50%);
  width: 1.75rem;
  height: 1.75rem;
  @include breakpoint(576) {
    width: 3rem;
    height: 3rem;
  }
}

.map-imagewrapper {
  position: relative;
  width: 5rem;
  padding-bottom: 56.25%;
  border-radius: 5px;
  border: solid #f4849f 2px;
  filter: drop-shadow(0px 12px 16px rgba(17, 0, 5, 0.18));
  overflow: hidden;
  @include breakpoint(576) {
    bottom: 1.2rem;
    width: 10rem;
    border: solid #f4849f 3px;
  }
}

.map-image {
  position: absolute;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.map-image-backer {
  position: absolute;
  object-fit: cover;
  width: 130%;
  height: 130%;
  filter: blur(15px);
  background: #000;
}


//this is to target only Internet Explorer
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

  .map {
    height: 390px;
    width: 600px;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .map-container {
    height: 390px;
    width: 600px;
    position: relative;
    margin: auto;
    margin-bottom: 2rem;
    margin-top: -2rem;

  }
  .map-pins-container {
    height: 430px;
    width: 600px;
    position: absolute;
    margin-top: 0rem;
  }
}