.main-logo {
  align-self: center;
  margin: 1.625rem 0 1rem;
  max-width: 145px;
  @include breakpoint(576) {
    max-width: 265px;
    margin: 1.625rem 0 1.4375rem;
  }
  @include breakpoint(679) {
    max-width: 100%;
  }
}

.card-prize {
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 0 -18px 20px rgba(#da3732, 0.1);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0.25rem;
  .card-img {
    border-radius: 0.6rem;
  }
  .card-heading {
    color: $hallmark-pink;
    font-size: 0.75rem;
    font-weight: $font-weight-bold;
    letter-spacing: 0;
    line-height: 1;
    margin-top: 1rem;
    margin-bottom: 0.4rem;
    @include breakpoint(768) {
      font-size: 1.375rem;
      letter-spacing: 0.05rem;
      margin-bottom: 0.6rem;
    }
  }
  .card-info {
    color: #292929;
    font-weight: $font-weight-medium;
    font-size: 0.625rem;
    text-align: center;
    @include breakpoint(768) {
      font-size: 1.115rem;
    }
  }
  @include breakpoint(768) {
    padding: 0.75rem;
    border-radius: 1rem;
  }
}
.prize-row {
  margin-top: 1.25rem;
  @include breakpoint(576) {
    margin-top: 2.375rem;
  }
}
.map {
  margin-top: 1.8rem;
  @include breakpoint(576) {
    margin-top: 3rem;
  }
  svg {
    height: 100%;
    width: 100%;
  }
}
.countdown {
  p {
    text-align: center;
    font-weight: $font-weight-bold;
    font-size: 1.4375rem;
    margin-bottom: 1.8125rem;
    @include breakpoint(768) {
      margin-bottom: 2.8125rem;
    }
  }
}
.countdown-group {
  display: flex;
  justify-content: center;
  .countdown-number {
    background-color: #fff;
    border: 2px solid #d4d4d4;
    border-radius: 0.5rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.07);
    color: $hallmark-pink;
    font-weight: $font-weight-bold;
    margin-right: 0.75rem;
    padding: 1.25rem;
    font-size: 1.75rem;
    line-height: 1;
    min-width: 78px;
    text-align: center;
    position: relative;
    &:last-of-type {
      margin-right: 0;
    }
    @include breakpoint(768) {
      border-radius: 1rem;
      font-size: 3rem;
      margin-right: 1.5rem;
      padding: 2rem;
      min-width: 120px;
    }
    .descriptor {
      position: absolute;
      top: -1.2rem;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
      font-size: 0.75rem;
      text-transform: uppercase;
      @include breakpoint(576) {
        top: -1.75rem;
        font-size: 1rem;
      }
    }
  }
}
.landing-gallery-card {
  display: flex;
  margin: 0.5rem 1rem 0.5rem;
  justify-content: space-around;
  flex-direction: column;
  text-align: center;
  .heart-total-container {
    margin-bottom: 0.5rem;
    @include breakpoint(576) {
      margin-bottom: 0;
    }
    .heart-total {
      font-size: 2.5rem;
      font-weight: $font-weight-bold;
      color: $hallmark-pink;
      margin-left: 1rem;

      &-heading {
        font-size: 2.25rem;
        font-weight: $font-weight-bold;
      }
    }
  }
  @include breakpoint(768) {
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    box-shadow: 0 -18px 20px rgba(#da3732, 0.1);
    border-radius: 1rem;
    flex-direction: row;
    margin: 2.5rem 1rem 0.5rem;
    padding: 2.5rem;
    text-align: left;
  }
  .btn-outline-primary {
    @include breakpoint(576) {
      width: 266px;
    }
    @include breakpoint(769) {
      width: 293px;
    }
    @include breakpoint(1200) {
      width: 423px;
    }
  }
}
.entry-row {
  margin: 0.5rem 0 1.5rem;
  display: flex;
  align-items: center;
  @include breakpoint(576) {
    margin: 0.5rem 1rem 0.5rem;
  }
  p {
    margin-bottom: 1rem;
    text-align: center;
    font-size: 1rem;
    @include breakpoint(576) {
      margin-bottom: 0;
      @include breakpoint(768) {
        font-size: 1.2rem;
      }
    }
  }
}
.card-countdown {
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 1rem;
  padding: 3rem 1rem 1rem;
  @include breakpoint(576) {
    margin-bottom: 3rem;
  }
}
.landing_main.container {
  position: relative;
  z-index: 2;
}
.time-seperator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 0.75rem;
  @include breakpoint(768) {
    margin-right: 1.5rem;
  }
  span {
    height: 0.5rem;
    width: 0.5rem;
    background: #c6a6a7;
    border-radius: 50%;
    margin-bottom: 0.75rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
.enter-now-callout {
  font-size: 1.3rem;
}
.prize-content {
  max-height: 400px;
  overflow-y: scroll;
  margin-top: 3rem;
  @include breakpoint(576) {
    max-height: 450px;
    margin-top: 4rem;
  }
  @include breakpoint(768) {
    max-height: 650px;
  }
  .prize-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      min-width: 600px;
    }
  }
  .prize-card {
    max-width: 300px;
    flex: 1 0 40%;
    margin-bottom: 0.5rem;
  }
  &.no-image {
    overflow: unset;
    max-height: 100%;
  }
}
.enter-now-cta {
  margin-top: 2.5rem;
}

.landing-hearthands {
  width: 100%;
  box-shadow: 0 18px 20px rgba(#da3732, 0.1);
  border-radius: 0.5rem;
  border: 5px white solid;
}

.landing-hearthands-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.landing-header {
  font-size: 1.5rem;
  text-align: center;
  width: 100%;
  @include breakpoint(768) {
    font-size: 2.2rem;
  }
  @include breakpoint(1200) {
    font-size: 2.5rem;
  }
}

.landing-subheader {
  text-align: center;
  width: 100%;
  font-size: 1.1rem;
  @include breakpoint(768) {
    font-size: 1.5rem;
  }
}
