.success.container {

    @include breakpoint(992) {
        width: 766px;
    }
}
.info-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include breakpoint(576) {
        flex-direction: row;
    }
    h1 {
        margin-bottom: 0;
    }
}
.success-subheader {
    color: #333333;
    display: block;
    font-size: 1.4rem;
    font-weight: $font-weight-bold;
    margin-top: 1rem;
    @include breakpoint(576) {
        font-size: 2rem;
        margin-top: 2rem;
    }
}
.card-download {
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    box-shadow: 0 -18px 20px rgba(#DA3732, .1);
    border-radius: 1rem;
    text-align: center;
    padding: 2rem 1rem 4rem;

    .btn-outline-primary {
        width: 100%;
        @include breakpoint(576) {
            width: 423px;
        }
    }
}
.card-earn {
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    box-shadow: 0 -18px 20px rgba(#DA3732, .1);
    border-radius: 1rem;
    padding: 3rem 1rem 1rem;
    text-align: center;
    @include breakpoint(768) {
        padding: 3rem 4rem 4rem;
    }
    .subheader {
        color: #333333;
        font-weight: $font-weight-bold;
        text-align: center;
        font-size: 1.375rem;
        display: block;
        line-height: 1.3;
        margin: 2.155rem 0 1.155rem;
        @include breakpoint(576) {
            margin: 2.155rem 0;
        }
        &.short {
            margin: 2.155rem 0 .75rem;
        }
    }
    .btn-primary {
        width: 100%;
        @include breakpoint(576) {
            width: 423px;
        }
    }
    .loading-container .hallmark-loader {
        transform: translateY(50%);
        margin: 10% auto;
    }
    .row-tight {
        [class^=col] {
            padding-right: 2.5px;
            padding-left: 2.5px;
        }
    }
    .watched {
        color: $hallmark-pink;
    }
}
.section-number {
    text-align: center;
    position: relative;
    margin-top: 2rem;
    .value {
        background: #fff;
        border: 1px solid #DE204E;
        border-radius: 50%;
        box-shadow: 0 13px 34px rgba(#DA3732, .04), 0 4px 10px rgba(#000000, .1);
        color: #DE204E;
        display: flex;
        align-items: center;
         justify-content: center;
        font-weight: $font-weight-bold;
        font-size: 1.5rem;
        padding: .25rem;
        height: 3rem;
        width: 3rem;
        z-index: 2;
        position: relative;
        margin: 0 auto;
    }
    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background: #E8DAE3;
        left: 0;
        top: calc(50% - 1px);
        z-index: 1;
    }
}
.image-preview {
    background: #FBF4F9;
    border-radius: 1.25rem;
    border: 1px solid #E8DAE3;
    padding: .75rem;
}
.watch-and-win {
    p {
        max-width: 400px;
        margin: 0 auto;
        margin-bottom: 1.5rem;
        font-weight: $font-weight-medium;
        font-size: 1.1rem;
        color: #4F4F4F;
        line-height: 1;
    }
    .btn-primary{
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 288px;
    }
}
.heart-input {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    @include breakpoint(576) {
        flex-direction: row;
    }
}
.video-preview {
    background: #FBF4F9;
    border-radius: .75rem;
    overflow: hidden;
    padding: .75rem;
    border: 1px solid #E8DAE3;
}