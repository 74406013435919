.isIE {
  #content-wrap {
    display: block;
  }

  .page-stall-game .game_stall-background {
    top: 0px;
    left: 0px;
  }

  .weekly-prize_header {
    left: 50%;
    transform: translate(-50%, 0);
  }

  .landing_card {
    @include breakpoint(1800) {
      min-width: calc(25vw - 194px);
      flex: initial;

      .landing_card-body_bg {
        height: calc((25vw - 194px) / (370 / 205));
      }

      .landing_card-footer_bg {
        height: calc((25vw - 194px) / (370 / 94));
      }
    }
  }
}
