.premiere-info {
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 3rem;
  margin-top: -2rem;
  @include breakpoint(lg) {
    padding-top: 3rem;
  }
  .btn.desktop {
    @include breakpoint(md) {
      padding: 10px 20px;
      font-size: 1.1rem;
    }
    @media screen and (min-width: 1480px) {
      padding: 10px 30px;
      font-size: 1.4rem;
    }
  }
}

.movie-details {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  .movie-status {

    @include breakpoint(sm) {
      margin-top: 0;
    }
    @include breakpoint(lg) {
      margin-left: 2rem;
    }

    img {
      margin-bottom: 0.25rem;
      @include breakpoint(sm) {
        margin-bottom: 1rem;
      }
    }
    p {
      color: $white;
      font-weight: 700;
      font-size: 1.9rem;
      margin-block-end: 0.5rem;
    }
    .hallmark-callout {
      margin-top: 0.25rem;
      color: $white;
      text-transform: uppercase;
      font-size: 0.5rem;
      letter-spacing: 0.05rem;
      font-weight: 400;
      @include breakpoint(365) {
        font-size: 0.6rem;
      }
      @include breakpoint(lg) {
        margin-top: 0.5rem;
      }
      @include breakpoint(lg) {
        font-size: 1rem;
        letter-spacing: 0.1rem;
      }

      span {
        font-weight: 700;
      }
    }
    .premiere-countdown {
      display: block;
      color: $white;
      font-size: 0.9rem;
      font-weight: 700;
      line-height: 1;
      margin-bottom: .25rem;
      @include breakpoint(lg) {
        min-width: 335px;
        font-size: 1.6rem;
        margin-bottom: .5rem;
      }
      span {
        width: 21px;
        display: inline-block;
        @include breakpoint(lg) {
          width: 37px;
        }
      }
      .divider {
        display: inline;
      }
      .countdown-text {
        width: auto;
      }
    }
    .btn.mobile {
      font-size: 0.8rem;
      padding: 3px 7px;
      width: auto !important;
      @include breakpoint(365) {
        padding: 3px 15px;
      }
      img {
        max-width: 10px;
        margin-bottom: 0;
        margin-top: -2px;
      }
    }
    .checkin-description-mobile {
      .checked-in-counter{
        display: inline;
         p{
        font-size: .6rem;
        margin-bottom: .25rem;
      }
    }
    }
  }
  .talent-image {
    max-width: 160px;
    @include breakpoint(363) {
      max-width: 190px;
    }
    @include breakpoint(sm) {
      max-width: 195px;
    }
    @include breakpoint(md) {
      max-width: 230px;
    }
    @include breakpoint(lg) {
      max-width: 100%;
    }
  }
  .movie-logo {
    max-width: 110px;
    @include breakpoint(363) {
      max-width: 130px;
    }
    @include breakpoint(sm) {
      max-width: 185px;
    }
    @include breakpoint(md) {
      max-width: 185px;
    }
    @include breakpoint(lg) {
      max-width: 100%;
    }
  }
}

.tweet-preview {
  background-color: $white;
  display: flex;
  padding: 1rem 1.5rem;
  align-items: flex-start;
  flex-direction: row;
  border-radius: 0.25rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.09);
  margin-bottom: 1rem;
  &:last-of-type {
    margin-bottom: 0;
  }
  img {
    max-height: 75px;
    max-width: fit-content;
  }
  .profile-image {
    margin-right: 1rem;
  }
  .username {
    font-weight: 800;
    font-size: 1.5rem;
  }

  .time {
    color: #acacac;
    text-transform: uppercase;
    font-weight: 500;
  }

  .tweet {
    font-size: 1.25rem;
    line-height: 1.2;
    margin-bottom: 0.3rem;

    .hashtag {
      color: #f35b5a;
    }
  }
}

.premier-party-info {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @include breakpoint(lg) {
    text-align: left;
  }
  @include breakpoint(lg) {
    align-items: flex-end;
  }
  @include breakpoint(xl) {
    text-align: center;
  }

  h1 {
    color: #fff;
    @include breakpoint(sm) {
      font-size: 1.2rem;
    }
    @include breakpoint(md) {
      font-size: 1.5rem;
    }
    @include breakpoint(lg) {
      font-size: 1.8rem;
    }
  }
  .btn {
    font-size: .7rem;
    @include breakpoint(lg) {
      font-size: 1rem;
    }
    img {
      margin-top: -2px;
      max-width: 10px;
      @include breakpoint(md) {
        max-width: 15px;
      }
      @include breakpoint(1480) {
        max-width: 25px;
      }
    }
  }
}

.live-pill {
  background: #db0032;
  border-radius: 2rem;
  padding: 0.25rem;
  text-transform: uppercase;
  position: relative;
  max-width: 200px;
  margin: 0 auto;

  .status-indicator {
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    left: 1rem;
    height: 15px;
    width: 15px;
    top: calc(50% - 7px);
  }

  p {
    color: $white;
    font-size: 1.25rem;
    font-weight: 800;
    letter-spacing: 0.2rem;
    margin-left: 1.5rem;
    margin-bottom: 0;
  }
}

.blob {
  transform: scale(1);
  animation: pulse-black 2s infinite;
}

.blob.white {
  background: white;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  animation: pulse-white 2s infinite;
}

@keyframes pulse-white {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.checked-in-counter {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  text-transform: uppercase;
  @include breakpoint(lg) {
    justify-content: center;
  }

  p {
    color: $white;
    margin-bottom: 0;
    font-size: 0.8rem;
    @include breakpoint(sm) {
      font-size: 1rem;
    }
  }
  img {
    margin-right: 0.5rem;
  }
}

.twitter-previews {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  @include breakpoint(lg) {
    margin-top: 0;
  }
  @media screen and (min-width: 1480px) {
    padding-left: 5rem;
  }
  .btn-twitter {
    margin-bottom: 1rem;
    font-size: .6rem;
    @include breakpoint(md) {
      font-size: .7rem;
    }
    @include breakpoint(xl) {
      font-size: .9rem;
    }
  }
  .twitter-timeline-container {
    background-color: $white;
    border-radius: 1rem;
    height: 100%;
    overflow: hidden;
      min-height: 500px;
  }
}

.premiere-main-body {
  padding-top: 1rem;
  @include breakpoint(lg) {
    padding-top: 3rem;
  }

  .premiere-play-header h1 {
    color: $ctc-blue;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    text-align: left;
    @include breakpoint(lg) {
      text-align: center;
      margin-bottom: 1.4rem;
    }
  }
  .poll-row {
    &:last-of-type .question-content.poll {
      margin-bottom: 0;
    }
  }
  .live {
    display: none;
  }
}
