.chimney-container {
  max-width: 812px;
  margin: 30px 12px 20px;
  border-radius: 0.5rem;
  background-color: #FDFAFA;
  box-shadow: 0 0 30px rgb(0 0 0 / 20%);
  overflow: hidden;
  @include breakpoint(md) {
    margin: 40px auto;
  }
  .chimney-embed {
    font-family: $albra;
    &.chimney-loading-color {
      background-color: #FDFAFA;
    }
    &.no-header {
      padding: 0 15px;
      @include breakpoint(sm) {
        padding: 30px;
      }
    }
    .chimney-title {
      font-size: 25px;
      font-weight: $font-weight-bold;
      line-height: calc(30 / 25);
      color: $text;
      margin-bottom: 20px;
      @include breakpoint(lg) {
        font-size: 32px;
      }
    }
    &.chimney-active-step- .chimney-title,
    &.chimney-active-step-complete .chimney-title {
      font-size: 19px;
      text-transform: uppercase;
    }
    .chimney-welcome-introduction p {
      font-size: 17px;
      line-height: calc( 24 / 17);
      margin-bottom: 20px;
      color: $text;
      @include breakpoint(sm) {
        margin-bottom: 30px;
        line-height: calc( 26 / 18);
      }
    }
    .chimney-meta {
      text-align: center;
    }
    .chimney-meta,
    .chimney-body {
      padding-left: 0;
      padding-right: 0;
    }
    .chimney-body {
      padding-bottom: 1px;
    }
    .chimney-step-intro {
      display: none;
    }
    .chimney-label {
      font-size: 18px;
      font-weight: $font-weight-medium;
      line-height: calc( 25 / 18);
      color: $ctc-blue;
    }
    .chimney-form-radio,
    .chimney-form-radio-check,
    .chimney-form-radio-label {
      width: 100%;
      height: 100%;
      min-height: 47px;
      font-weight: normal;
    }
    .chimney-form-radio {
      padding: 5px 0;
    }
    .chimney-form-input,
    .chimney-form-radio-check {
      border-width: 1px;
      border-color: $input-outline;
      border-radius: 3.5px;
      background-color: $white;
      &:before {
        border-radius: 50%;
        height: 16px;
        width: 16px;
        top: 50%;
        transform: translateY(-50%);
        left: 13px;
        border: none;
      }
    }
    .chimney-form-radio-checkbox {
      border-width: 1px;
      border-color: $input-outline;
      z-index: 10;
      &:after {
        display: none;
      }
    }
    .chimney-form-radio-label {
      z-index: 11;
      span {
        max-width: calc(100% - 5px);
        width: 100%;
        color: $text;
      }
    }

    .chimney-form-radio-input:checked {
      ~.chimney-form-radio-check {
        border: 1px solid #CDAD60;
        background-color: $white;
        &:before {
          border: none;
          background: linear-gradient(180deg, #FFEBB9 0%, #CDAD60 100%);
        }
      }
      ~.chimney-form-radio-checkbox {
        border: 1px solid #CDAD60;
      }
      ~.chimney-form-radio-label span {
        font-weight: $font-weight-medium;
      }
    }
    .chimney-input-container.--submit {
      text-align: center;
    }
    .chimney-btn,
    .chimney-entry-button .chimney-btn {
      background: linear-gradient(180deg, rgba(219, 0, 50, 1) 0%, rgba(186, 0, 32, 1) 100%);
      padding: 24px 44px;
      border-color: theme('primary');
      color: $white;
      transition: all 200ms ease-in-out;
      font-family: $albra;
      font-size: 1.2rem;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      letter-spacing: 0.08em;
      line-height: 120%;
      border-radius: 300px;
      box-shadow: 0 0 15px rgba(219, 0, 50, .5);
      @include breakpoint(md) {
        font-size: 1.4rem;
      }
      &::after {
        position: relative;
        content: '';
        display: inline-block;
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg width='18' height='16' viewBox='0 0 18 16' fill-rule='evenodd' clip-rule='evenodd' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.06931 14.0077C7.6024 14.4357 7.57086 15.1612 7.99886 15.6281C8.42686 16.095 9.15232 16.1265 9.61923 15.6985L17.0904 8.84994C17.1087 8.83341 17.1264 8.81627 17.1436 8.79855C17.3213 8.61572 17.4265 8.38673 17.4575 8.14928C17.4641 8.09939 17.4673 8.04936 17.4673 7.9995C17.4672 7.72272 17.3676 7.44514 17.1658 7.22503C17.1368 7.19339 17.1065 7.16374 17.0749 7.13611L9.4986 0.295659C9.02848 -0.128806 8.30327 -0.09179 7.8788 0.378335C7.45434 0.84846 7.49135 1.57367 7.96148 1.99813L13.3388 6.85317L1.67959 6.85317C1.04619 6.85317 0.532725 7.36664 0.532725 8.00003C0.532725 8.63343 1.04619 9.14689 1.67959 9.14689L13.372 9.1469L8.06931 14.0077Z' fill='%23FCD574'/%3E%3C/svg%3E%0A");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 17px;
        height: 17px;
        transform: translate(0, -10%);
        transition: all 200ms ease-out;
        margin-left: 8px;
      }
      &:hover {
        @media (hover: hover) {
          &:after {
            transform: translate(5px, -10%);
            -webkit-transform: translate(5px, -10%);
          }
        }
      }
      &.chimney-form-input {
        font-size: 1.2rem;
        @include breakpoint(md) {
          font-size: 1.4rem;
        }
      }
      &.chimney-loading:after {
        position: static;
        margin: 0;
        margin-left: 8px;
      }
    }
    .chimney-sticky-container.sticky .chimney-entry-button {
      position: static;
      margin: auto;
      &:before,
      &:after {
        display: none;
      }
    }
    .chimney-entry-button {
      margin-top: 20px;
    }
    .chimney-complete .chimney-complete-message {
      margin-top: 10px;
      p {
        color: $ctc-red;
        font-size: 26px;
        font-weight: $font-weight-bold;
        padding: 40px 0 30px;
        @include breakpoint(md) {
          font-size: 34px;
        }
      }
    }
  }
  .chimney-closed {
    .chimney-icon.chimney-icon-closed {
      display: none;
    }
    .chimney-closed-message {
      font-size: 20px;
      color: $ctc-red;
      padding: 20px 0 30px;
      @include breakpoint(md) {
        font-size: 26px;
        line-height: calc(31 / 26);
      }
    }
  }
  .chimney-preloader-spinner {
    div {
      border-color: #cfd8db transparent transparent transparent;
    }
  }
}