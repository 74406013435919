.question-card {
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
    @include breakpoint(0, xs) {
        border-radius: .5rem;
    }

    .radio-button {
        border-bottom-left-radius: 2rem;
        right: 0;
        top: 0;
        position: absolute;
        width: 56px;
        height: 56px;
        background-color: $white;
        z-index: 2;
        @include breakpoint(0, xs) {
            border-bottom-left-radius: 1rem;
            width: 34px;
            height: 34px;
        }
    }
    input {
        cursor: pointer;
        position: absolute;
        z-index: 1;
        top: -2px;
        left: -2px;
        width: 44px;
        height: 44px;
        margin: 0;
        opacity: 0;
        @include breakpoint(0, xs) {
            width: 26px;
            height: 26px;
        }
    }
    .radios-label {
        display: inline-block;
        margin-bottom: 0;
        padding: 8px 15px 5px;
        cursor: pointer;
        touch-action: manipulation;
        @include breakpoint(0, xs) {
            padding: 5px 14px 5px;
        }
    }
    .radios-label:before {
        content: "";
        box-sizing: border-box;
        position: absolute;
        top: 8px;
        left: 12px;
        width: 36px;
        height: 36px;
        border: 3px solid $ctc-red;
        border-radius: 50%;
        background: transparent;
        @include breakpoint(0, xs) {
            border-width: 2px;
            top: 6px;
            left: 7px;
            width: 22px;
            height: 22px;
        }
    }

    .radios-label:after {
        content: "";
        display: inline-block;
        position: static;
        transform: rotate(45deg);
        height: 19px;
        width: 9px;
        margin-top: 8px;
        margin-left: 5px;
        opacity: 0;
        @include breakpoint(0, xs) {
            height: 12px;
            width: 6px;
            margin-top: -5px;
            margin-left: 1px;
        }
    }
    .radios-input:checked+.radios-label{
        &:after {
        opacity: 1;
    }
    &:before {
        background-color: $ctc-red;
        border: none;
    }

}
    &.correct {
        .radio-button {
            background-color: #FCE8B3;
        }
        &:after {
        content: '';
        left: 0;
        top: 0;
        position: absolute;
        height: 100%;
        border: 5px solid #FCE8B3;
        border-radius: 1rem;
        width: 100%;
        @include breakpoint(0, xs) {
            border-radius: .5rem;
            border-width: 3px;
        }
    }
}
&.correct {
    .radios-label:after {
        border: none;
        border-radius: 0;
        position: static;
        border-bottom: 3px solid #FCE8B3;
        border-right: 3px solid #FCE8B3;
        opacity: 1;
        margin-left: 1px;
        margin-top: 5px;
        @include breakpoint(sm) {
            margin-left: 10px;
            margin-top: 6px;
        }
    }
   .radios-label:before {
    background-color: $ctc-red;
    }



}
&.incorrect {
    .radio-button {
        background-color: $ctc-red;
    }
        .radios-label:after {
            border: none;
            position: static;
            border-radius: 0;
            opacity: 1;
            content: '\d7';
            margin-top: -2px;
            margin-left: -3px;
            transform: rotate(0deg);
            color: $white;
            font-size: 1.2rem;
            @include breakpoint(sm) {
                font-size: 2rem;
                margin-top: -6px;
                margin-left: 4px;
            }

        }
        .radios-label:before {
            border: 2px solid $white!important;
            @include breakpoint(sm) {
                border: 3px solid $white!important;
            }
        }
        &:after {
            content: '';
            left: 0;
            top: 0;
            position: absolute;
            height: 100%;
            border: 5px solid $ctc-red;
            border-radius: 1rem;
            width: 100%;
            @include breakpoint(0, xs) {
                border-radius: .5rem;
                border-width: 3px;
            }
        }
    }
  &-label-group {
      padding: 0!important;
      width: 100%;
      &:before,
      &:after {
      display: none;
  }
}
}
.question-header {
    display: flex;
    margin: 1.5rem auto;
    max-width: 95%;
    justify-content: center;
    @include breakpoint(0, xs) {
        margin: .75rem auto;
    }
    h1 {
        color: $white;
        text-align: left;
    }
    .pill {
        background: $white;
        border-radius: 2rem;
        color: $ctc-blue;
        display: flex;
        font-weight: $font-weight-bold;
        padding: .25rem 1.25rem;
        font-size: 1.5rem;
        max-width: 120px;
        margin: auto;
        margin-right: 0;
        @include breakpoint(0, xs) {
            padding: .15rem .5rem;
            font-size: 1rem;
        }
        .icon {
            width: 30px;
            margin-left: .5rem;
            @include breakpoint(0, xs) {
                width: 22px;
                margin-left: .25rem;
            }
        }
    }
}
.question-content {
    background: $white;
    border-radius: 0 0 1rem 1rem;
    padding: 2rem 3rem 1rem;
    position: relative;
    overflow: hidden;
    transition: all 200ms ease-out;
    box-shadow: 0 0 20px rgba(0,0,0,.2);
    @include breakpoint(0, xs) {
        padding:.75rem;
        margin: 0;
    }
    @include breakpoint(sm, md) {
        padding: 1.5rem;
    }
    .col-6 {
        margin-bottom: 1.5rem;
        @include breakpoint(0, xs) {
            margin-bottom: .5rem;
        }
    }
    h1 {
        color: #463C24;
        font-size: 1.8rem;
        line-height: 1.2;
        letter-spacing: .01rem;
        margin-bottom: 2rem;
        font-weight: 500;
        @include breakpoint(0, xs) {
            font-size: 1.2rem;
            margin-bottom: 1rem;
            margin-top: .5rem;
        }
        @include breakpoint(sm, md) {
            font-size: 1.45rem;
        }
    }

    &.selection-made {
    .incorrect,
    .selectionMade {
        opacity: .6;
    }
        .correct {
        opacity: 1;
        color: $ctc-green;
        }
        .incorrect {
            color: $ctc-red;
            opacity: 1;
        }
        &.answered label,
        &.answered input {
            cursor: default;
        }
        &.unanswered label,
        &.unanswered input {
            cursor: pointer;
        }
    }
    button {
        display: block;
        margin: 0 auto;
        margin-top: 2rem;
        width: 100%;
        @include breakpoint(0, xs) {
            margin-bottom: .5rem;
            margin-top: .5rem;
        }
    }
    form {
    display: flex;
    width: 100%;
    max-width: 100%;
    flex-wrap: wrap;
    padding: 0;
    }
    &.poll {
        margin-left: 0;
        margin-right: 0;
        border-radius: 1rem;
        margin-bottom: 1rem;
        padding: 1.5rem 1rem;
         h1 {
            font-size: 1.3rem;
            text-align: left;
            text-transform: none;
            max-width: calc(100% - 80px);
            @include breakpoint(0, xs) {
                font-size: 1rem;
            }
        }
        .quiz-question {
            .poll-answer {
                position: relative;
                margin-bottom: 1rem;
                .bar{
                    position: absolute;
                    height: 100%;
                    background: linear-gradient(90deg, rgba(#CDAD60, 1) 0%, rgba(#FFEBB9,1) 100%);
                    left: 45px;
                    width: 0px;
                    transition: width 1s ease-in-out;
                    max-width: 380px;
                }
                label {
                    width: 100%;
                }
                label.selected {
                    &:before,
                    &:after {
                          color: #CDAD60;
                    }

                }
            }
            .poll-result-number {
                position: absolute;
                right: 0;
                top: calc(50% - 10px);
                font-weight: 700;
            }
            .poll-text {
                margin-left: .5rem;
                max-width: calc(100% - 30px);
                display: inline-block;
                @include breakpoint(0, xs) {
                    font-size: .8rem;
                }
            }

        }
        &.locked {
            font-weight: 700;
            padding: 3.5rem 2.5rem;
            padding-top: 1rem;
            color: #666666;
            @include breakpoint(md) {
                padding: 1rem 3.5rem 3.5rem;
            }
            .quiz-question {
            flex-direction: column;
            align-items: center;
            }
        }
        .icon-lock {
            max-width: 90px;
            display: block;
            margin-bottom: .75rem;
            margin: 0 auto;
        }
    }
    .poll-pill {
        padding: .5rem;
        border: 1px solid #DFDFDF;
        border-radius: 2rem;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
        width: 46px;
        text-align: center;
        font-weight: 700;
        font-size: 1rem;
        position: absolute;
        top: 10px;
        right: 10px;
        @include breakpoint(lg) {
            font-size: 1.2rem;
        }
        .img-icon {
            max-width: 25px;
            @include breakpoint(lg) {
            max-width: 30px;
            max-height: 24px;
            margin-top: -3px;
            }
        }
        &.ticket {
            width: 80px;
        }
    }
}
.question-header-image {
    border-radius: 1rem 1rem 0 0;
    overflow: hidden;
    flex-shrink: 0;
    margin-right: calc(var(--bs-gutter-x)/ 2);
    margin-left: calc(var(--bs-gutter-x)/ 2);
    margin-top: var(--bs-gutter-y);
    @include breakpoint(sm) {
        padding: 0;
        margin: 0;
    }
    img {
        border-radius: inherit;
    }
}
.progress{
    background-color: rgba($white, .4);
    border-radius: 1rem;
    margin: 0 auto;
    height: auto;
    margin-bottom: 2rem;
    max-width: 95%;
    min-height: 24px;
    @include breakpoint(0, xs) {
        border-radius: .25rem;
        margin-bottom: 1rem;
    }
.progress-bar {
    background-color: $white;
    color: $ctc-blue;
    padding: 0rem .9rem 0 .75rem;
    font-size: 1rem;
    text-align: right;
    font-weight: 700;
    overflow: visible;
    @include breakpoint(0, xs) {
        font-size: .75rem;
    }
}
&.complete {
    .progress-bar {
        background-color: $white;
        color: $ctc-blue;
        text-align: center;
    }
}
}

.card-franchise {
    box-shadow: 0px 1px 3px rgba(0,0,0,.24);
    border-radius: .75rem;
    overflow: hidden;
    color: $white;

    &.hallmarkChannel .tune-in{
        background-color: #DB0032;
    }
    &.hallmarkMoviesAndMysteries .tune-in{
        background-color: #C3551D;
    }
    .tune-in {
        bottom: 0;
        padding: .5rem .25rem;
        font-size: .5rem;
        font-weight: $font-weight-bold;
        letter-spacing: .03rem;
        text-align: center;
        position: absolute;
        width: 100%;
        @include breakpoint(sm) {
            font-size: .8rem;
        }
        @include breakpoint(md) {
            font-size: .5rem;
        }
        @include breakpoint(lg) {
            font-size: .5rem;
        }
        @include breakpoint(xl) {
            font-size: .4rem;
        }
        @include breakpoint(1480) {
            font-size: .5rem;
        }

        .franchise-logo {
            width: 32px;
            @include breakpoint(sm) {
                width: 60px;
            }
            @include breakpoint(md) {
                width: 35px;
            }
            @include breakpoint(lg) {
                width: 45px;
            }
            @include breakpoint(xl) {
                width: 38px;
            }
            @include breakpoint(1480) {
                width: 48px;
            }
        }
    }
}
.question-container {
    box-shadow: 0 0 20px rgba(0,0,0,.1);
    padding: 0;
}

.image-question-row {
    transition: all 200ms ease-in;
    .col-12 {
        @include breakpoint(sm) {
        padding: 0;
        }
        img {
            border-radius: 1rem 1rem 0 0;
            overflow: hidden;
            background-color: $white;
            @include breakpoint(sm) {
            min-height:264px;
            }
            @include breakpoint(md) {
            min-height:275px;
            }
            @include breakpoint(lg) {
            min-height:290px;
            }
            @include breakpoint(xl) {
            min-height:287px;
            }
            @include breakpoint(1480) {
            min-height: 348px;
            }
        }
    }
}

.trivia-tunein-container {
    @include breakpoint(sm) {
        padding: 0;
    }
}
.trivia-tune-in {
    background: $white;
    border-radius: 1rem;
    margin-top: 1rem;
    padding: 1rem 1.5rem;
    box-shadow: 0 0 20px rgb(0 0 0 / 20%);
    position: relative;
    overflow: hidden;
    @include breakpoint(md) {
        margin-top: 1.5rem;
    }

    p {
        color: $text;
        margin-bottom: 0;
        font-size: .8rem;
        margin-left: 38px;
        font-weight: $font-weight-medium;
        line-height: 1.2;
        @include breakpoint(sm) {
            font-size: 1rem;
        }
        @include breakpoint(md) {
            font-size: 1.1rem;
            font-weight: $font-weight-bold;
            margin-left: 75px;
        }
        @include breakpoint(xl) {
            font-size: 1.25rem;
        }
    }
    .info-backer {
        position: absolute;
        height: 100%;
        width: 40px;
        background: $ctc-red;
        left: 0;
        top: 0;
        @include breakpoint(md) {
            width: 60px;
        }
    }
    .icon-info {
        position: absolute;
        left: 22px;
        top: calc(50% - 15px);
        max-width: 30px;
        height: auto;
        @include breakpoint(md) {
            left: 38px;
            top: calc(50% - 20px);
            max-width: 40px;
            height: auto;
        }
    }
}