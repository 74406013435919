.card-gallery {
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    box-shadow: 0 -18px 20px rgba(#DA3732, .1);
    border-radius: 1rem;
}
.gallery-item {
    position: relative;
    border-radius: .25rem;
    overflow: hidden;
    margin-bottom: 1rem;
    @media (min-width: 576px){
      margin-bottom: 0m;
    }
    .gallery-info {
        color: $white;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
        font-size: .6rem;
        position: absolute;
        bottom: .25rem;
        left: 0;
        z-index: 3;
        padding: 0 0.5rem;
        width: 100%;
        @media (min-width: 576px){
          font-size: 1rem;
          flex-direction: column;
          justify-content: flex-start;
          bottom: .5rem;
          left: .5rem;
        }
        @media (min-width: 1200px){
          padding: 0 1rem;
        }
        img {
          margin-right: .15rem;
          @media (min-width: 576px){
            margin-right: .25rem;
          }
        }
        .icon-pin {
          position: relative;
          max-width: 6px;
          margin-left: 0;
          @media (min-width: 576px){
            max-width: 10px;
          }
        }
        .location-data {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: .6rem;
          @media (min-width: 576px){
            font-size: 1rem;
          }

        }
        span,
        .location-data {
          max-width: 45px;
          text-overflow: ellipsis;
          white-space: nowrap;
          position: relative;
          overflow: hidden;
          @media (min-width: 576px){
            max-width: 100%;
          }
        }
    }
    &:before {
        background: linear-gradient(0deg, rgba(0,0,0,.75) 0%, rgba(0,0,0,0) 100%);
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 50%;
        width: 100%;
    }
}

.gallery-item {
  .gallery-image {
    box-shadow: none;
    z-index: 3;
    img {
      padding: 0;
    }
    .video-js.vjs-has-started {
      z-index: 99;
    }
  }
  .btn-link.btn-sm {
    margin-left: 0;
    justify-content: flex-start;
    margin: 0 1rem 0 0;
    @media (min-width: 768px){
      margin: 0 2rem 0 0;
    }
  }
  p {
    color: $text;
    padding: 0 1rem ;
    font-weight: $font-weight-medium;
    position: relative;
    z-index: 3;
  }
  &-img {
    position: relative;
    .username {
      bottom: 1rem;
      color: $white;
      font-weight: $font-weight-medium;
      left: 1rem;
      position: absolute;
      z-index: 4;
      font-size: 1.5rem;
    }
  }
  .btn-nav {
    border-radius: 50%;
    background-color: $ctc-blue;
    min-height: 50px;
    min-width: 50px;
    padding: .25rem;
    text-align: center;
    width: auto!important;
    z-index: 5;
    margin-bottom: 0;
    margin: 0 0 0 0.25rem;
    @media (min-width: 768px){
      min-height: 65px;
      min-width: 65px;
      padding: 1rem;
      margin: 0 0 0 1rem;
    }

    .icon-next {
      transform: rotate(180deg);
    }
  }

  .gallery-nav-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    margin-top: -2.75rem;
  }
  .btn-like {
    margin-bottom: 0;
    top: 0;
    right: 0;
    justify-content: center;
    width: 100%;
    padding: 1rem 2rem;
    span {
      display: flex;
    }
  }
  .username.text-only {
    bottom: 1rem;
    color: #333333;
    font-weight: $font-weight-medium;
    padding: 0 1rem;
    z-index: 4;
    font-size: 1.7rem;
  }
  .btn-link.btn-sm {
    position: relative;
    margin-bottom: 1rem;
    margin-top: -2rem;
    padding-left: 0;
    &:before {
      margin: 0 .75rem 0 0;
    }
  }
  .gallery-image {
    padding-bottom: 56.25%;
    background-color: #000;
    &.video {
      padding-bottom: 0;
    }
  }
  .btn-like {
    position: relative;
  }
}

.gallery-nav {
  margin-top: 1rem;
  margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    margin: 1rem .75rem;
  .desktop-button {
    display: none;
  width: auto;
  @media (min-width: 992px){
    display: block;

  }
}
.mobile-button {
  justify-content: center;
  display: flex;
  margin-bottom: 2rem;

  @media (min-width: 992px){
    display: none;
  }
  .mobile-nav {
    display: inline-block;
    width: 60px;
    height: 60px;
    padding: 12px 24px;
    margin: auto 1rem;
    &:before {
      margin-left: 17px;
      top: 18px;
    }
    &:after {
      margin-left: -5px;
      top: 18px;
    }
    &.btn-arrow {
      width: auto!important;
      @media (min-width: 576px){
        width: 60px!important;
      }
      &:before,
      &:after {
        margin-left: auto;
        top: auto;
        transform: none;

      }
    }
   }
}
@media (min-width: 768px){
  margin-bottom: 2rem;
  margin-top: 1rem;
}
}
.gallery-image {
  border-radius: .3rem;
  background-color: #fff;
  margin-bottom: 0.75rem;
  position: relative;
  padding-bottom: 63.1%;
  background-color: #000;
  overflow: hidden;
}
.gallery-image {
  img {
  position: absolute;
  object-fit: contain;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 2;
}
.backer {
  z-index: 1;
  object-fit: cover;
  filter: blur(15px);
  z-index: 1;
}
}
.gallery-image .username {
  color: #fff;
  z-index: 9;
  font-size: .9rem;
  font-weight: 500;
  @media (min-width: 576px) and (max-width: 768px){
    font-size: 1.1rem;
  }
  @include breakpoint(1200) {
    font-size: 1.2rem;
  }
}
.gallery-container-grid  {
  padding: 1rem 0.75rem 0;
  @media (min-width: 576px) {
    padding: 2.5rem 1.5rem 0;
  }
  .btn-like {
    position: relative;
    top: 0;
    right: 0;
    width: 80%;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem!important;
    @media (min-width: 576px) {
      margin-bottom: 2rem!important;
    }
  }
  }
  .love {
    color: #fff;
    input {
      display: none;
      &:checked ~ {
        label {
          i {
            color: #fff;
          }
        }
      }
    }
    label {
      display: flex;
      cursor: pointer;
      text-align: center;
      user-select: none;
      i {
        color: #fff;
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        margin-right: .5rem;
      }
    }
    .material-icons {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 20px;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      @include breakpoint(576) {
        font-size: 24px;
      }
    }
  }
  .gallery-index {
    .gallery-header {
      text-align: center;
      font-weight: $font-weight-medium;
      margin-bottom: 2rem;
      font-size: 1.125rem;
    }
  }
.gallery-card {
  .btn-primary {
    height: 32px;
    @include breakpoint(576) {
      height: 46px;
    }
  }
}
.modal-cta {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  width: 100%;
  @media (min-width: 576px){
    width: 70%;
  }
  .btn-primary {
    width: 100%;
    &:first-of-type {
      margin-bottom: 1rem;
    }
  }
}
.gallery-button {
  margin-top: 1rem;
  display: block;
   @media (min-width: 576px){
    margin-top: 3rem;
  }
}
.background-img-group {
  &:after{
    content: '';
    background: linear-gradient(0deg, rgba(0,0,0,.8) 0%, rgba(0,0,0,0) 50%);
    height: 100%;
    width: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: 2;
  }
}
.gallery-item.gallery-image.gallery-page {
  .gallery-info {
  flex-direction: row;
  justify-content: space-between;
  left: 0;
}
.location-data,
.location-data span {
  letter-spacing: .05rem;
  font-size: .9rem;
  max-width: 70px;
  @media (min-width: 576px) and (max-width: 768px){
    font-size: 1.1rem;
  }
  @media (min-width: 1200px){
    font-size: 1.2rem;
    overflow: visible;
    text-overflow: unset;
    max-width: 100%;
  }
}
}
.card-gallery {
.gallery-item .gallery-info .location-data {
  display: none;
  @media (min-width: 769px){
    display: flex;
  }
}
.gallery-item .gallery-info span,
.gallery-item .gallery-info .location-data {
  max-width: 70px;
  @media (min-width: 576px){
    max-width: 100%;
  }
}
}
.page-counter {
  text-align: center;
  color: $hallmark-pink;
  font-weight: $font-weight-bold;
  font-size: 1.2rem;
}